import React, { Component, useRef } from 'react';
import { connect } from 'react-redux'
import UtilsService from '../../../services/utils.service';
import { mapStateToProps } from '../../../selectors/MainScreenSelector';
import { mainScreenAction } from '../../../actions/MainScreenActions';
import CategorieFilter from '../categorie.filter';
import InputTextAnswer from '../../answers/inputtextanswer.component';
import ChoixSimpleAnswerContainer from '../../answers/simplechoiceanswer.component';
import RadioButtonAnswer from '../../answers/radiobuttonanswer.component'
import PillBoxContainerAnswer from '../../answers/pillboxanswer.component'
import InputNumericAnswerContainer from '../../answers/inputnumericanswer.component';
import DatePickerAnswerContainer from '../../answers/datepickeranswer.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleDown, faChevronCircleUp, faCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import SnakeBarComponent from '../../snakebar/snakebar.component';
import './Questions.css';
import { ReactComponent as PointExclamationCercleRouge } from '../../../svg/point-exclamation-circle.svg';
import CheckValidationVert from '../../../logo/check-validation-vert.png';
import MenuQuestion from "./menuQuestion/menuQuestion.component";
import { Translation, withTranslation } from 'react-i18next';


// const endItemAutoScroll = useRef(null);
class QuestionsComponent extends Component {


    // const endItemAutoScroll = useRef(null);
    constructor(props) {
        super(props);

        this.state = {
            categorie: '',
            selected: [],
            isAllDisplay: false,
            showAlertSuccess: false,
            questions: this.props.questionsFromMainScreen,
            sortConfig: [
                { column: 'libelle', type: 'label', isSortAsc: false },
                { column: 'categorie_libelle', type: 'label', isSortAsc: false },
                { column: 'date_debut_validite', type: 'date', isSortAsc: false },
                { column: 'date_maj', type: 'custom_date_reponse', isSortAsc: false },
                { column: 'duree_validite', type: 'number', isSortAsc: false },
                { column: 'statut', type: 'number', isSortAsc: false }
            ],
            searchTxt: ''
        }

        this.itemsRefs = {};
        this.showAlert = this.showAlert.bind(this);
    }

    componentDidMount() {
        this.changeSortedBy('libelle');
    }



    componentWillReceiveProps(props) {
        if (props.questionsFromMainScreen !== this.state.questions) {
            this.setState({
                questions: props.questionsFromMainScreen
            })
        }
    }

    onClickHandler = (e, index) => {
        const hiddenElement = e.currentTarget.nextSibling;
        if (hiddenElement.className.indexOf("collapse show") > -1) {
            hiddenElement.classList.remove("show");
        } else {
            hiddenElement.classList.add("show");
            this.itemsRefs[index].scrollIntoView({ behavior: "smooth", block: "nearest", inline: "nearest" });
        }
        let selectedItems = [];
        if (!this.state.selected.includes(index)) {
            selectedItems = [...this.state.selected, index];
        } else {
            selectedItems = this.state.selected.filter(item => item !== index);
        }

        this.setState({
            selected: selectedItems
        })
    };

    getReponses() {
        let questionsToDisplay;
        if (this.state.sortBy) {
            questionsToDisplay = UtilsService.dynamicSort(this.state.sortConfig, this.state.questions, this.state.sortBy, false);
        } else {
            questionsToDisplay = this.state.questions;
        }
        return questionsToDisplay
            .filter(qst => qst.reponses.length > 0)
            .filter(qst => this.props.filter.qstRep_filter === 'ALL' ? true : qst.categorie_code === this.props.filter.qstRep_filter)
    }

    getQuestions() {
        let questionsToDisplay;
        if (this.state.sortBy) {
            questionsToDisplay = UtilsService.dynamicSort(this.state.sortConfig, this.state.questions, this.state.sortBy, false);
        } else {
            questionsToDisplay = this.state.questions;
        }
        return questionsToDisplay
            .filter(qst => qst.reponses.length <= 0 || qst.is_reponse_expired).filter(qst => this.props.filter.qstNonRep_filter === 'ALL' ? true : qst.categorie_code === this.props.filter.qstNonRep_filter);
    }

    changeSortedBy = (column) => {

        let newConfig = this.state.sortConfig;
        this.props.initQuestions(UtilsService.dynamicSort(newConfig, this.state.questions, column, true));
        this.setState({
            sortBy: column,
            sortConfig: newConfig,
        });
    }

    handleDiplayAll = () => {
        this.setState({
            isAllDisplay: true
        });
    }

    getDureeValideeHeader = () => {

        return (<div className="col-2 tri-selector reponsive-header-column" onClick={() => this.changeSortedBy('duree_validite')}>
            <h3 className="text-important">
                <Translation>{(t) => <p>{t('DUREE_VALIDITEE')}</p>}</Translation>
            </h3>
            <i className={this.state.sortConfig.find(currentConfig => currentConfig.column === 'duree_validite').isSortAsc
                ? "fa fa-angle-up arrow-margin custom-self-center" : "fa fa-angle-down arrow-margin custom-self-center"}></i>
        </div>);
    }

    getDureeValiditee = (question) => {
        return question.duree_validite === null ?
            (<p className="text-center">-</p>) : (question.is_reponse_expired ?
                <span className="text-danger">
                    <h3>
                        <div className="custom-display-flex">
                            <div className="col-3">
                                <PointExclamationCercleRouge />
                            </div>
                            <div className="col-9">
                                <Translation>{(t) => <p>{t('MISE_A_JOUR_NECESSAIRE')}</p>}
                                </Translation>
                            </div>
                        </div>
                    </h3>
                </span>
                : this.getDisplayDureeValditee(question));

    }

    getDisplayDureeValditee(question) {

        let row;
        if (question.duree_validite !== 0) {
            var moment = require('moment');

            let expirationDate = moment(question.reponses[0].date_fin_validite);
            let todayDate = moment();
            var duration = moment.duration(expirationDate.diff(todayDate));
            let mois = duration.months();
            let jours = duration.days();
            let annees = duration.years();
            let globalMois = mois + (annees * 12);

            if (globalMois) {
                if (jours) {
                    row = <div>{globalMois} <Translation>{(t) => <p>{t('MOIS')}</p>}</Translation> {jours} <Translation>{(t) => <p>{t('JOURS')}</p>}</Translation></div>;
                } else {
                    row = <div>{globalMois} <Translation>{(t) => <p>{t('MOIS')}</p>}</Translation></div>;
                }
            } else {
                row = <div>{jours} <Translation>{(t) => <p>{t('JOURS')}</p>}</Translation></div>;
            }

        } else {
            row = "-"
        }


        return row;
    }


    displayHeader() {
        return (
            <div className='row px-4'>

                <div className="col-1 reponsive-header-column">
                    <h3 className="text-important">
                        <Translation>{(t) => <p>{t('ETAT')}</p>}</Translation>
                    </h3>

                </div>

                <div className={this.props.answered ? "col-4 tri-selector reponsive-header-column" : "col-7 tri-selector reponsive-header-column"} onClick={() => this.changeSortedBy('libelle')}>
                    <h3 className="text-important">
                        <Translation>{(t) => <p>{t('NOM')}</p>}</Translation>
                    </h3>
                    <i className={this.state.sortConfig.find(currentConfig => currentConfig.column === 'libelle').isSortAsc
                        ? "fa fa-angle-up arrow-margin custom-self-center" : "fa fa-angle-down arrow-margin custom-self-center"}></i>
                </div>
                <div className="col-1 tri-selector reponsive-header-column" onClick={() => this.changeSortedBy('date_debut_validite')}>
                    <h3 className="text-important">
                        <Translation>{(t) => <p>{t('DATE_CREATION')}</p>}</Translation>
                    </h3>
                    <i className={this.state.sortConfig.find(currentConfig => currentConfig.column === 'date_debut_validite').isSortAsc
                        ? "fa fa-angle-up arrow-margin custom-self-center" : "fa fa-angle-down arrow-margin custom-self-center"}></i>
                </div>

                <div className="col-2 tri-selector reponsive-header-column" onClick={() => this.changeSortedBy('categorie_libelle')}>
                    <h3 className="text-important">
                        <Translation>{(t) => <p>{t('CATEGORIE')}</p>}</Translation>
                    </h3>
                    <i className={this.state.sortConfig.find(currentConfig => currentConfig.column === 'categorie_libelle').isSortAsc
                        ? "fa fa-angle-up arrow-margin custom-self-center" : "fa fa-angle-down arrow-margin custom-self-center"}></i>
                </div>


                { this.props.answered ? (
                    <div className="col-1 tri-selector reponsive-header-column" onClick={() => this.changeSortedBy('date_maj')}>
                        <h3 className="text-important">
                            <Translation>{(t) => <p>{t('DATE_REPONSE')}</p>}</Translation>
                        </h3>
                        <i className={this.state.sortConfig.find(currentConfig => currentConfig.column === 'date_maj').isSortAsc
                            ? "fa fa-angle-up arrow-margin custom-self-center" : "fa fa-angle-down arrow-margin custom-self-center"}></i>
                    </div>
                ) : ''
                }
                { this.props.answered ?
                    this.getDureeValideeHeader()
                    : ''}


            </div>
        )
            ;
    }

    showAlert(alert) {
        this.setState({
            typeAlert: alert.type,
            messageAlert: alert.message,
            showAlertSuccess: true
        })
    }

    hideAlertSuccess = () => this.setState({ showAlertSuccess: false });

    getColorCategorie(libelle_categorie) {
        var categorieColor = null;
        if (this.props.categories.length > 0) {
            this.props.categories.forEach(categorie => {
                if (libelle_categorie === categorie.libelle) {
                    categorieColor = categorie.color;
                }
            });
        }
        return categorieColor ? (<FontAwesomeIcon icon={faCircle} color={categorieColor} > </FontAwesomeIcon>) : '';
    }

    getTitle() {

        let title;

        if (this.props.answered) {
            title = <Translation>{(t) => <p className="text-information">{t('QUESTIONS_REPONDUES')}</p>}</Translation>
        } else {
            title = <Translation>{(t) => <p className="text-information">{t('QUESTIONS_EN_ATTENTE_DE_REPONSES')}</p>}</Translation>;
        }

        return title;
    }

    getIconEtat(question) {
        let row;
        if (question.signalements.filter(signalement => signalement.user_maj.toString() === sessionStorage.getItem("user") && signalement.id_projet.toString() === this.props.idProjet).length) {
            if (question.is_reponse_expired) {
                row = <div className="custom-display-grid"><PointExclamationCercleRouge className="icon-expiration-double" /><FontAwesomeIcon size="3x" color="#FFD42A" icon={faExclamationTriangle} /></div>;
            } else {
                row = <FontAwesomeIcon size="3x" color="#FFD42A" icon={faExclamationTriangle} />;
            }
        } else if (question.is_reponse_expired) {
            row = <PointExclamationCercleRouge className="icon-expiration" />;
        }
        else {
            row = <img className="icon-size-custom pl-2" src={CheckValidationVert} />;
        }
        return row;
    }

    handleSearchTxt = (e) => {
        this.setState({
            searchTxt: e
        });
    }

    render() {
        return (

            <div className="bg-white custom-background">
                <SnakeBarComponent open={this.state.showAlertSuccess} duration={3000} severity={this.state.typeAlert} message={this.state.messageAlert} hide={this.hideAlertSuccess} />
                <div className='row'>
                    <div className="col-12 col-md-6 d-flex align-items-center custom-padding-bot">
                        <h2 className='text-information'>

                            {this.getTitle()}

                        </h2>
                    </div>

                    <div className="col-12 col-md-6 text-right">
                        {this.props.answered ? (
                            <div className='d-inline-block text-center py-3 pl-5'>
                                <h2 className="text-information">
                                    <i className='fa fa-database'></i> {this.state.questions.filter(qst => qst.reponses.length > 0).length}
                                </h2>
                                <Translation>{(t) => <p className="legende-question">{t('TRAITEES')}</p>}</Translation>
                            </div>
                        )
                            : (
                                <div className='d-inline-block text-center py-3 pl-5'>
                                    <h2 className="text-information">
                                        <i className='fa fa-database'></i> {this.state.questions.filter(qst => qst.reponses.length === 0).length}
                                    </h2>
                                    <Translation>{(t) => <p className="legende-question">{t('NON_TRAITEES')}</p>}</Translation>
                                </div>
                            )}
                        <div className='d-inline-block text-center py-3 pl-5'>
                            <h2 className="text-information"> <i className='fa fa-th-list'></i> {this.state.questions.length} </h2>
                            <Translation>{(t) => <p className="legende-question">{t('QUESTIONS_TOTALES')}</p>}</Translation>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-3">
                        <input className="form-control search-input " type="text"
                            onChange={(e) => this.handleSearchTxt(e.target.value)}
                            value={this.state.searchTxt}
                            placeholder={this.props.t('Recherche')} aria-label="Search" />
                    </div>
                    <div className='col-12 col-md-9'>
                        <CategorieFilter componentName={this.props.answered ? 'questionsRepondues' : 'questionsNonRepondues'}></CategorieFilter>
                    </div>
                </div>

                { (!this.props.answered ? this.getQuestions().length > 0 : this.getReponses().length > 0) ? (
                    <div>
                        {this.displayHeader()}
                        {(!this.props.answered ? this.getQuestions() : this.getReponses()).filter((data) => {
                            if (!this.state.searchTxt) return data;
                            else if (data.libelle.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase()
                                .includes(this.state.searchTxt.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase()))
                                return data;
                        }).map((question, index) => {
                            return (
                                (index > 2 ? this.state.isAllDisplay : true) ?
                                    (
                                        <div className="row">
                                            <div key={index} ref={el => (this.itemsRefs[index] = el)} className="col-12 px-0 py-0 my-2 hover-collapse">
                                                <div onClick={(e) => this.onClickHandler(e, index)} className='row card-header collapse-margin'>

                                                    <div className="col-1 d-flex align-items-center" >
                                                        {this.getIconEtat(question)}
                                                    </div>

                                                    <div className={this.props.answered ? "col-4 d-flex align-items-center" : "col-7 d-flex align-items-center"} >
                                                        <div>
                                                            <h3 className="text-important mb-0">{question.libelle}</h3>
                                                            <span class="description-question">{question.complement}</span>
                                                        </div>
                                                    </div>

                                                    <div className="col-1 d-flex align-items-center" >
                                                        <p className="text-important">{UtilsService.handleDateFormat(question.date_debut_validite)}</p>
                                                    </div>

                                                    <div className="col-2 d-flex align-items-center">
                                                        <h3 className="text-important">{this.getColorCategorie(question.categorie_libelle)} {question.categorie_libelle}</h3>
                                                    </div>

                                                    {this.props.answered ? <div className='col-1 custom-self-center custom-reponse'>
                                                        {UtilsService.handleDateFormat(question.reponses[0].date_maj)}
                                                    </div> : ""}

                                                    {this.props.answered ? <div className="col-2 d-flex align-items-center">
                                                        {this.getDureeValiditee(question)}
                                                    </div> : ""}

                                                    <div className='toggle-icon-color col-1 custom-self-center custom-display-flex' >
                                                        <div className="col-6">
                                                            <FontAwesomeIcon color="#00aa9b" icon={this.state.selected.includes(index) ? faChevronCircleUp : faChevronCircleDown} className="hover-cursor-pointer float-right icon-size-custom fa-3x" />
                                                        </div>
                                                        <div className="col-6 custom-align-center">
                                                            <MenuQuestion question={question} showAlert={this.showAlert} idProjet={this.props.idProjet}></MenuQuestion>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="collapse">
                                                    <hr className="custom-border-question"></hr>
                                                    <div className="row card-body bg-contrast-white mx-0">
                                                        <div className="col-12">
                                                            {this.getAnswers(question)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                    : '')
                        })}
                    </div>
                ) : (
                        <div className="text-center">
                            <p>{this.props.answered ? <Translation>{(t) => <span>{t('MERCI_REPONDRE_QUESTIONS_CI_DESSUS')}</span>}</Translation> : <Translation>{(t) => <span>{t('VOUS_AVEZ_PLUS_REPONSE')}</span>}</Translation>}</p>
                        </div>
                    )
                }

                {(this.state.questions.length > 3 && !this.state.isAllDisplay && (this.props.answered ? this.getReponses().length > 0 : this.getQuestions().length > 0)) ?
                    <div className="col-12 white-background text-center">
                        <span className="btn-more-qst hover-cursor-pointer" onClick={this.handleDiplayAll}>
                            <Translation>{(t) => <p>{t('VOIR_PLUS_DE_QUESTIONS')}</p>}</Translation>
                        </span>
                    </div> : ''
                }
            </div>
        );
    }
    getAnswers(question) {
        let row = "";
        switch (question.type) {
            case "SAISIE_TEXT":
                row = <InputTextAnswer key={question.id} question={question} showAlert={this.showAlert} idProjet={this.props.idProjet} answered={this.props.answered} isDirection={false}></InputTextAnswer>;
                break;
            case "LISTE_DEROULANTE":
                row = <ChoixSimpleAnswerContainer key={question.id} question={question} showAlert={this.showAlert} idProjet={this.props.idProjet} answered={this.props.answered} isDirection={false}></ChoixSimpleAnswerContainer>;
                break;
            case "RADIO_BOUTON":
                row = <RadioButtonAnswer key={question.id} question={question} showAlert={this.showAlert} idProjet={this.props.idProjet} answered={this.props.answered} isDirection={false}></RadioButtonAnswer >;
                break;
            case "LISTE_TAG":
                row = <PillBoxContainerAnswer key={question.id} question={question} showAlert={this.showAlert} idProjet={this.props.idProjet} answered={this.props.answered} isDirection={false}></PillBoxContainerAnswer>;
                break;
            case "SAISIE_NUMERIQUE":
                row = <InputNumericAnswerContainer key={question.id} question={question} showAlert={this.showAlert} idProjet={this.props.idProjet} answered={this.props.answered} isDirection={false}></InputNumericAnswerContainer>;
                break;
            case "DATE":
                row = <DatePickerAnswerContainer question={question} showAlert={this.showAlert} idProjet={this.props.idProjet} answered={this.props.answered} isDirection={false}></DatePickerAnswerContainer>;
                break;
        }
        return row;
    }
}

const Questions = connect(mapStateToProps, mainScreenAction)(withTranslation()(QuestionsComponent));

export default Questions;
