import React, { Component } from 'react';
import { Button, Modal} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { Translation, withTranslation } from 'react-i18next';
import '../../../modificationQuestion/confirmationModification.css';
import { connect } from 'react-redux';
import { mapStateToProps } from '../../../../selectors/MainScreenSelector';
import { mainScreenAction } from '../../../../actions/MainScreenActions';
import questionsService from "../../../../services/questions.service";


class ConfirmationSuppressionComponent extends Component {
    constructor(props) {
        super(props);
    }


    deleteQuestion = (e) => {
        questionsService.deleteQuestion(this.props.id).then(() => {
            this.props.showAlert({
                type: 'success',
                message: <Translation>{(t) => <p>La question a été supprimée</p>}</Translation>
            });
        });
        this.props.refreshQuestion(this.props.id);
        this.props.hide();
        e.stopPropagation();
    }

    render() {

        return <div onClick={e => e.stopPropagation()}>
            <Modal show={this.props.show}  size="xl" backdrop="static" onClick={e => e.stopPropagation()}>
                <Modal.Header className="flex-column align-items-center custom-header">
                    <div className="row custom-justify-content">
                        <Button onClick={this.props.hide} className="btn-outline text-important rounded-pill" >
                            <FontAwesomeIcon icon={faChevronLeft} /> <Translation>{(t) => <p>{t('RETOUR')}</p>}</Translation>
                        </Button>
                    </div>
                    <div className="row">   
                    <div className="col-10 offset-1 text-align-center text-center pt-4">
                        <b>
                            <h1 className="text-important">
                                <Translation>{(t) => <p>Cette action supprimera la totalité des signalements et des réponses liées à cette question</p>}</Translation>
                            </h1>
                        </b>
                    </div>
                    </div>
                </Modal.Header>
                <Modal.Footer>
                    <div className="col custom-align-center">
                        <Button className="custom-button custom-red-button p-10" onClick={(e) => { this.deleteQuestion(e) }} >
                            
                                    <Translation>{(t) => <p>Supprimer la question</p>}</Translation>
                            
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    }
}



const ConfirmationSuppression = connect(mapStateToProps, mainScreenAction)(ConfirmationSuppressionComponent);

export default withTranslation()(ConfirmationSuppression);