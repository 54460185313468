import moment from 'moment';

class UtilsService {

    dynamicSort(globalConfig, questions, column, updateSortAsc) {

        let currentConfig = globalConfig.find(conf => conf.column === column);
        if (updateSortAsc) {
            let lastSortConfig = globalConfig.find(conf => conf.isSortAsc === true);
            if (lastSortConfig && lastSortConfig.column !== column) {
                lastSortConfig.isSortAsc = !lastSortConfig.isSortAsc;
            }
            currentConfig.isSortAsc = !currentConfig.isSortAsc;
        }

        let newTriQuestion = questions;

        if (currentConfig.type === 'label') {
            if (currentConfig.isSortAsc) {
                newTriQuestion.sort((a, b) => a[currentConfig.column].localeCompare(b[currentConfig.column]));
            } else {
                newTriQuestion.sort((a, b) => b[currentConfig.column].localeCompare(a[currentConfig.column]));
            }
        } else if (currentConfig.type === 'number') {
            if (currentConfig.isSortAsc) {
                newTriQuestion.sort((a, b) => (a[currentConfig.column] ? a[currentConfig.column] : 0) - (b[currentConfig.column] ? b[currentConfig.column] : 0));
            } else {
                newTriQuestion.sort((a, b) => b[currentConfig.column] - a[currentConfig.column]);
            }
        } else if (currentConfig.type === 'date') {
            if (currentConfig.isSortAsc) {
                newTriQuestion.sort((a, b) => new Date(a[currentConfig.column]) - new Date(b[currentConfig.column]));
            } else {
                newTriQuestion.sort((a, b) => new Date(b[currentConfig.column]) - new Date(a[currentConfig.column]));
            }
        } else if (currentConfig.type === 'custom_date_reponse') {
            if (currentConfig.isSortAsc) {
                newTriQuestion.sort((a, b) =>
                    (a.reponses && a.reponses.length > 0 ? new Date(a.reponses[0][currentConfig.column]).getTime() : null) - (b.reponses && b.reponses.length > 0 ? new Date(b.reponses[0][currentConfig.column]).getTime() : null)
                );
            } else {
                newTriQuestion.sort((a, b) => 
                    (b.reponses && b.reponses.length > 0 ? new Date(b.reponses[0][currentConfig.column]).getTime() : null) - (a.reponses && a.reponses.length > 0 ? new Date(a.reponses[0][currentConfig.column]).getTime() : null)
                    );
                }
            }
        return newTriQuestion;
    }

    handleDateFormat = (date) => {
        return date ? moment(date).format('DD/MM/YYYY') : '';
    }

    extractQstsWithCategories = (noeuds) => {
        let questions = [];
        noeuds.map(noeud => {
            noeud.categories.map(categorie => {
                categorie.questions.map(qst => {
                    qst.categorie_libelle = categorie.libelle;
                    qst.categorie_code = categorie.code;
                    qst.noeud_id = noeud.id;
                    if (qst.valeurs_questions) {
                        qst.valeurs_questions.push(qst.valeurs_questions.splice(qst.valeurs_questions.map(function (valeurQuestion) { return valeurQuestion.libelle }).indexOf("Autres"), 1)[0]);
                    }
                    qst.reponses = this.filtreReponse(qst);
                    questions.push(qst);
                });
            });
        });
        return questions;
    }

    extractActiveQstsWithCategories = (noeuds) => {
        let questions = [];
        noeuds.map(noeud => {
            noeud.categories.map(categorie => {
                categorie.questions.map(qst => {
                    if (qst.statut) {
                        qst.categorie_libelle = categorie.libelle;
                        qst.categorie_code = categorie.code;
                        qst.noeud_id = noeud.id;

                        let isReponseExpired = false;
                        qst.reponses = this.filtreReponse(qst);

                        if (qst.valeurs_questions) {
                            qst.valeurs_questions.push(qst.valeurs_questions.splice(qst.valeurs_questions.map(function (valeurQuestion) { return valeurQuestion.libelle }).indexOf("Autres"), 1)[0]);
                        }

                        if (qst.reponses && qst.reponses.length && qst.duree_validite !== null && qst.duree_validite !== 0) {
                            isReponseExpired = this.calculeReponseExpired(qst.reponses[0].date_fin_validite);
                        }

                        qst.is_reponse_expired = isReponseExpired;

                        questions.push(qst);
                    }
                });
            });
        });
        return questions;
    }

    calculeReponseExpired(date_fin_validite) {
        let today = new Date().getTime();
        let finValidite = new Date(date_fin_validite).getTime();
        let result = finValidite < today;
        return result;
    }

    filtreReponse = (question) => {
        let reponsesFromNoeud = [];
        reponsesFromNoeud = question.reponses.filter(reponse => (
            reponse.id_noeud === question.noeud_id
        ));
        return reponsesFromNoeud;
    }

    getUpdatedDate(date, days) {
        const copy = new Date(date);
        copy.setDate(copy.getDate() + days);
        return copy;
    }

    isFromBiggerThanTo(dtmfrom, dtmto) {
        return new Date(dtmfrom).getTime() >= new Date(dtmto).getTime();
    }

    isDiplayUpdateRequired(dtmfrom, duree) {
        if (duree) {
            return this.isFromBiggerThanTo(new Date(), this.getUpdatedDate(dtmfrom, duree));
        }
        return false;
    }

    isEmpty(value) {
        return (typeof value === "undefined" || value === null);
    }

    getResponseIndicatortsForDirector(categories) {
        let nbrRsp = 0;
        let total = 0;
        let nbrQstNonRep = 0;
        let nbrQstPerimees = 0;
        if (categories) {
            categories.forEach(cat => {
                total = total + cat.questions.length;
                cat.questions.forEach(qst => {
                    let tmpReponsesNoeuds = [];

                    if (qst.reponses && qst.reponses.length > 0) {
                        qst.reponses.map((reponse) => {
                            let currentReponseNoeud = { question: reponse.id_question, noeud: reponse.id_noeud }
                            if (!tmpReponsesNoeuds.some(elem => { return elem.noeud === currentReponseNoeud.noeud && elem.question === currentReponseNoeud.question })) {
                                nbrRsp = nbrRsp + 1;
                                if (this.calculeReponseExpired(reponse.date_fin_validite)) {
                                    nbrQstPerimees = nbrQstPerimees + 1;
                                }
                            }
                            tmpReponsesNoeuds.push(currentReponseNoeud)
                        })
                    } else {
                        nbrQstNonRep = nbrQstNonRep + 1;
                    }
                })
            });
        }
        return {
            nbNonRep: nbrQstNonRep,
            nbRep: nbrRsp,
            nbPerimees: nbrQstPerimees
        };
    }

    getIndecatorsProjectsForDirector(projets, categories) {
        let under25 = 0, under50 = 0, under75 = 0, more75 = 0;
        let nbrQstsTotal = 0;
        let nbrQsts = 0;
        let nbrQstsRepAll = 0;

        let pourcent = 0;
        let nbrQstsRep = 0;
        let nbrQstPerimees = 0;
        let nbNonRep = 0;
        if (projets && projets.length > 0) {
            projets.forEach(projet => {
                nbrQsts = 0;
                nbrQstsRep = 0;
                projet.noeuds.forEach(noeud => {
                    noeud.categories.filter((categorie) => categories.map((categorieUser) => {
                        return categorieUser.id
                    })
                        .includes(categorie.id))
                        .forEach(categorie => {
                            nbrQsts = nbrQsts + categorie.questions.length;
                            categorie.questions.filter(qst => qst.statut).forEach(question => {
                                nbrQstsTotal++;
                                if (question.reponses && question.reponses.length > 0 && question.reponses.some(rsp => rsp.id_noeud === noeud.id)) {
                                    nbrQstsRepAll++;
                                    nbrQstsRep++;
                                    let tmpReponse = question.reponses.filter(rsp => rsp.id_noeud === noeud.id);
                                    if (tmpReponse.length > 0 && this.calculeReponseExpired(question.reponses[0].date_fin_validite)) {
                                        nbrQstPerimees++;
                                    }
                                } else {
                                    nbNonRep++;
                                }
                            })
                        });
                });
                pourcent = parseInt(((100 * nbrQstsRep) / nbrQsts).toFixed(0), 10);
                if (pourcent <= 25) {
                    under25++;
                } else if (pourcent > 25 && pourcent <= 50) {
                    under50++;
                } else if (pourcent > 50 && pourcent <= 75) {
                    under75++;
                } else if (pourcent > 75) {
                    more75++;
                }
            })
        }
        return {
            nbrQstsRepAll,
            nbrQstPerimees,
            nbNonRep,
            nbrQstsTotal,
            under25,
            under50,
            under75,
            more75
        }

    }

    extractIndicatorForActiveAndInactiveQstsAndSignalements = (categories) => {

        let actives = 0, inactives = 0, signalements = 0;
        categories.map(categorie => {
            categorie.questions.map(qst => {
                if (qst.statut) {
                    actives++;
                } else {
                    inactives++;
                }
                if (qst.signalements.length > 0) {
                    signalements++;
                }
            });
        });

        return {
            actives: actives,
            inactives: inactives,
            signalements: signalements
        };
    }

    getOccurenceAnswers = (question) => {
        let answers = {};
        answers.nbAnswers = question.reponses.length;
        answers.valeursQuestions = question.valeurs_questions.map((valeur) => {
            return { libelle: valeur.libelle, nbAnswer: question.reponses.filter(element => element.valeur === valeur.libelle).length };
        }
        )
        return answers;
    }

}

export default new UtilsService();