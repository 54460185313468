import React from 'react';
import {  InputGroup, FormControl } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons'
export default class CustomCalendarComponent extends React.Component{

    constructor(props){
      super(props);
    }

    render() {
        return (
            <InputGroup className="mb-3"  onClick={this.props.onClick} >
            <FormControl
              className="font-weight-bold"
              aria-describedby="basic-addon1"
              value={this.props.ipDate}
              onChange={(e)=>this.props.handleIpChange(e.target.value)}
              disabled={(this.props.answered && !this.props.edition) || this.props.isDirection}/>
              <InputGroup.Prepend>
              <InputGroup.Text id="basic-addon1"><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
            </InputGroup.Prepend>
           </InputGroup>
          );
    }
}