import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import Button from "../custom_button/custom_button"
import { connect } from "react-redux";
import { mainScreenAction } from "../../actions/MainScreenActions";
import { mapStateToProps } from "../../selectors/MainScreenSelector";
import projetsService from "../../services/projets.service";
import AnswersService from "../../services/reponse.service"
import utilsService from "../../services/utils.service";
import CustomCalendarInput from "./customcalendarinput.component";
import ModificationQuestion from "../modificationQuestion/modificationQuestion.component";
import DatePicker, { registerLocale } from "react-datepicker";
import moment from 'moment';
import fr from "date-fns/locale/fr";
import "react-datepicker/dist/react-datepicker.css";
import { Translation } from 'react-i18next';

registerLocale("fr", fr); // register it with the name you want
const momentDateFormat = "DD/MM/YYYY";

class DatePickerAnswer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showAlertSuccess: false,
            dpDate: '',
            ipDate: this.props.answered ? this.props.question.reponses[0].valeur : '',
            isSubmittable: false,
            edition: false
        };
        this.clearForm = this.clearForm.bind(this);
    }

    componentWillReceiveProps(props) {
        if (props.answered && props.question.reponses !== this.props.question.reponses) {
            this.setState({
                ipDate: props.answered ? props.question.reponses[0].valeur : ''
            })
        }
    }

    clearForm() {
        this.setState({
            isSubmittable: false,
            ipDate: this.props.answered ? this.props.question.reponses[0].valeur : '',
            edition: false
        });
    }

    editAnswer = () => {
        this.setState({ edition: true });
    }

    deleteAnswer = () => {
        AnswersService.deleteAnswer(this.props.question.id, this.props.question.noeud_id).then(() => {
            this.setState({ showAlertSuccess: true, edition: false });
            projetsService.getProjetById(this.props.idProjet).then((resp) => {
                const questions = utilsService.extractActiveQstsWithCategories(resp.data[0].noeuds);
                this.props.initQuestions(questions);
                this.props.showAlert({
                    type: 'success',
                    message: <Translation>{(t) => <p>{t('REPONSE_SUPPRIMEE')}</p>}</Translation>
                });
            });
        })
    }

    handleDPChange(val) {
        this.setState({ dpDate: val, ipDate: moment(val).format(momentDateFormat), isSubmittable: true });
    };

    handleIpChange(val) {
        let d = moment(val, momentDateFormat);
        if (d.isValid() && val.length > 9) {
            this.setState({ dpDate: d.toDate(), isSubmittable: true });
        } else {
            this.setState({ isSubmittable: false });
        }
        this.setState({ ipDate: val });
    }

    hideAlertSuccess = () => this.setState({ showAlertSuccess: false });

    submitAnswer = () => {
        if (this.state.edition || this.props.question.is_reponse_expired) {
            AnswersService.updateAnswer(this.props.question, this.state.ipDate).then(() => {
                this.setState({ showAlertSuccess: true, edition: false });
                projetsService.getProjetById(this.props.idProjet).then((resp) => {
                    const questions = utilsService.extractActiveQstsWithCategories(resp.data[0].noeuds);
                    this.props.initQuestions(questions);
                    this.props.showAlert({
                        type: 'success',
                        message: <Translation>{(t) => <p>{t('REPONSE_MODIFIEE')}</p>}</Translation>
                    });
                });
            })
        }
        else {
            AnswersService.postAnswer(this.props.question, this.state.ipDate).then(() => {
                this.setState({ isSubmittable: true, ipDate: '', dpDate: '' });
                projetsService.getProjetById(this.props.idProjet).then((resp) => {
                    const questions = utilsService.extractActiveQstsWithCategories(resp.data[0].noeuds);
                    this.props.initQuestions(questions);
                });
                this.props.showAlert({
                    type: 'success',
                    message: <Translation>{(t) => <p>{t('REPONSE_ENREGISTREE')}</p>}</Translation>
                });
            })
        }
    }

    getButtons() {
        let buttons;
        if (this.props.answered && !this.state.edition) {
            buttons = <div className="col-4 pr-0 text-right">
                <Button className="margin-bottom-10" action="importante" onClick={this.editAnswer} > <Translation>{(t) => <p>{t('MODIFIER')}</p>}</Translation> </Button>
                <Button className="margin-top-10" action="suppression" onClick={this.deleteAnswer} > <Translation>{(t) => <p>{t('SUPPRIMER')}</p>}</Translation> </Button>
            </div>;
        } else if (this.props.isDirection) {
            buttons = <div className="col-4 pr-0 text-right">
                <ModificationQuestion question={this.props.question} idProjet={this.props.idProjet} showAlert={this.props.showAlert} setQuestionModify={this.props.setQuestionModify} />
                <Button className="margin-top-10" action="suppression" block variant="outline-danger" onClick={() => this.props.deleteAnswerDirection(this.props.question.id)} > <Translation>{(t) => <p>{t('SUPPRIMER')}</p>}</Translation> </Button>
            </div>;
        } else {
            buttons = <div className="col-4 pr-0 text-right">
                <Button className="margin-bottom-10" action="importante" onClick={this.submitAnswer} disabled={!this.state.isSubmittable} > <Translation>{(t) => <p>{t('VALIDER')}</p>}</Translation> </Button>
                <Button className="margin-top-10" action="simple" onClick={this.clearForm} disabled={!this.state.ipDate} > <Translation>{(t) => <p>{t('ANNULER')}</p>}</Translation> </Button>
            </div>;
        }

        return buttons;
    }

    render() {
        return (
            <>
                <Form ref={form => this.form = form}>
                    <div className="row mx-0">
                        <div className="pl-0 col-8">
                            <div className="my-1">
                                {this.props.isDirection ? (<p className="text-information"><Translation>{(t) => <b>{t('TYPE_DE_REPONSE')}</b>}</Translation><Translation>{(t) => <>{t('Date')}</>}</Translation> </p>) : ''}
                            </div>
                            <InputGroup>
                                <DatePicker
                                    dateFormat="dd/MM/yyyy"
                                    locale="fr"
                                    selected={this.state.dpDate}
                                    disabled={this.props.answered && !this.state.edition}
                                    onChange={value => this.handleDPChange(value)} customInput={
                                        <CustomCalendarInput
                                            ipDate={this.state.ipDate}
                                            handleIpChange={(val) => this.handleIpChange(val)}
                                            onClick={this.onClick}
                                            answered={this.props.answered}
                                            edition={this.state.edition}
                                            isDirection={this.props.isDirection}>
                                        </CustomCalendarInput>
                                    }
                                    showMonthDropdown
                                />
                            </InputGroup>
                        </div>

                        {this.getButtons()}

                    </div>
                </Form>
            </>
        );
    }
}

const DatePickerAnswerContainer = connect(mapStateToProps, mainScreenAction)(DatePickerAnswer);

export default DatePickerAnswerContainer;
