import React, { Component } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Accueil from "./components/accueil/accueil.component";
import AccueilDirecteur from "./components/accueil_directeur/accueilDirecteur.component";
import NavBar from "./components/navbar/navbar.component";
import MainScreenComponent from "./components/mainScreen/mainScreen";
import MainScreenDirecteurComponent from "./components/mainScreen_directeur/mainScreen_directeur.component";
import { Route } from "react-router-dom/cjs/react-router-dom.min";
import AuthService from "./services/auth.service";
import UserService from './services/users.service';
import Amplify, { Auth } from "aws-amplify";
import {AmplifyAuthenticator, AmplifySignIn, AmplifyConfirmSignUp, AmplifyForgotPassword}  from "@aws-amplify/ui-react";
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import { I18n } from 'aws-amplify';
import translationFr from "./translation/translationFr.json";
import translationEn from "./translation/translationEn.json";

I18n.putVocabularies({en: translationEn, fr: translationFr});

Amplify.configure({
  Auth: {
     usernameAttributes: 'email',

    // (required) only for Federated Authentication - Amazon Cognito Identity Pool ID
    identityPoolId: "eu-west-3:36a545c5-730a-40d4-b891-248a9af2db19",

    // (required)- Amazon Cognito Region
    region: "eu-west-3",

    // (optional) - Amazon Cognito User Pool ID
    userPoolId: "eu-west-3_7JsIwb5FC",

    // (optional) - Amazon Cognito Web Client ID (26-char alphanumeric string, App client secret needs to be disabled)
    userPoolWebClientId: "72h2nbqrmo3ecoh2adu6ilrrte",

    // (optional) - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: true,

    // (optional) - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    //authenticationFlowType: "USER_PASSWORD_AUTH",

    // (optional) - Manually set key value pairs that can be passed to Cognito Lambda Triggers
    //clientMetadata: { myCustomKey: "myCustomValue" },

    // (optional) - Hosted UI configuration

    oauth: {
      domain: "myproject-prism-gfi",
      scope: ["phone", "email", "profile", "openid", "aws.cognito.signin.user.admin"],
      redirectSignIn: "http://localhost:8081/",
      redirectSignOut: "http://localhost:8081/",
      clientId: "72h2nbqrmo3ecoh2adu6ilrrte",
      responseType: "code", // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
  }
});

const App = () => {
  const [authState, setAuthState] = React.useState();
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [role, setRole] = React.useState('');

  const handleConfirmation = async () => {
      try {
          await Auth.signOut();
              window.open("/","_self");
      } catch (error) {
          console.log('error signing out: ', error);
      }
  };

  const handleUser = (email) => {
          UserService.getUserByEmail(email)
                                            .then(response => {
                                              const roles = response.data.roles.map(role => role.libelle.toLowerCase());
                                              AuthService.login(roles);
                                              setRole(roles);
                                              sessionStorage.setItem('user', response.data.id);
                                              sessionStorage.setItem('email', email);
                                              setIsLoaded(true);
                                            })
                                            .catch(e => {
                                              console.log(e);
                                            });       
  };

  React.useEffect(() => {
        return onAuthUIStateChange((nextAuthState, authData) => {
            setAuthState(nextAuthState);
            Auth.currentAuthenticatedUser().then(user => {
              if(!!user && !!user.attributes && !!user.attributes.email){
                  handleUser(user.attributes.email);
              }
          })
          .catch(err => {
            console.error("Utilisateur non authentifié.")
        });
        });
    }, []);


  return authState === AuthState.SignedIn && isLoaded? (
                        <Router>
                          <NavBar />
                          <Route path={"/"} render={() => AuthService.onEnter(role)} />
                          <Route exact path={"/accueil-dp-cp"} component={Accueil} />
                          <Route exact path={"/accueil-directeur"} component={AccueilDirecteur} />
                          <Route exact path={"/projet/:id"} component={MainScreenComponent} />
                          <Route exact path={"/questions"} component={MainScreenDirecteurComponent} />
                        </Router>
                      ): <AmplifyAuthenticator usernameAlias="email">
                            <AmplifySignIn slot="sign-in"  usernameAlias="email" 
                            hideSignUp hideSignInAws>
                              <div slot="federated-buttons"></div>
                              <AmplifyConfirmSignUp
                                slot="confirm-sign-up"
                                handleSubmit={handleConfirmation}
                                usernameAlias="email"
                              ></AmplifyConfirmSignUp>
                            </AmplifySignIn>
                            <AmplifyForgotPassword
                                slot="forgot-password"
                                usernameAlias="email"
                              ></AmplifyForgotPassword>
                          </AmplifyAuthenticator>;
}

export default App;
