import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationFr from "./translation/translationFr.json";
import translationEn from "./translation/translationEn.json";
import detector from "i18next-browser-languagedetector";

const resources = {
    en: {
        translation: translationEn
    },
    fr: {
        translation: translationFr
    }
};

i18n
    .use(detector) // Auto detect la langue du navigateur
    .use(initReactI18next) 
    .init({
        resources,
        lng: "fr", // Par défaut langue fr
        fallbackLng: "fr", // Utilisation language fr si la langue detecté n'est pas pris en compte
        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;