import http from "../http-common";

class ProjetsDataService {

  isEmpty(value){
    return (typeof value === "undefined" || value === null);
  }

  getProjetsByIdUser(idUser) {
    if(this.isEmpty(idUser)){
      throw new Error('L\'idUser ne peut être null');
    }
    return http.get(`/projets/user/${idUser}`);
  }

  getProjetById(idProjet) {
    if(this.isEmpty(idProjet)){
      throw new Error('L\'idProjet ne peut être null');
    }
    return http.get(`/projets/`+ idProjet);
  }
}

export default new ProjetsDataService();