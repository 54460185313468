import React from "react";
import { Form } from "react-bootstrap";
import Button from "../custom_button/custom_button"
import { connect } from "react-redux";
import { mainScreenAction } from "../../actions/MainScreenActions";
import { mapStateToProps } from "../../selectors/MainScreenSelector";
import projetsService from "../../services/projets.service";
import AnswersService from "../../services/reponse.service"
import utilsService from "../../services/utils.service";
import { Translation, withTranslation } from 'react-i18next';
import ModificationQuestion from "../modificationQuestion/modificationQuestion.component";

class ChoixSimpleAnswer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            answer: this.props.answered ? this.props.question.reponses[0].valeur : '',
            autres: this.props.answered ? this.props.question.reponses[0].saisie_libre : '',
            responsesList: ['', ...props.question.valeurs_questions],
            edition: false,
            answersPercent: utilsService.getOccurenceAnswers(this.props.question)

        };
        this.handleChangeInputText = this.handleChangeInputText.bind(this);
        this.handleChangeSelect = this.handleChangeSelect.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.question !== this.props.question) {
            this.setState({
                answersPercent: utilsService.getOccurenceAnswers(this.props.question)
            })
        }
    }

    componentWillReceiveProps(props) {
        if (props.question.valeurs_questions !== this.props.question.valeurs_questions) {
            this.setState({
                responsesList: ['', ...props.question.valeurs_questions]
            })
        }
    }

    handleChangeInputText(e) {
        this.setState({ autres: e.target.value })
    }

    handleChangeSelect(e) {
        this.setState({ answer: e.target.value })
    }

    cancelAnswer = () => {
        this.setState({
            answer: this.props.answered ? this.props.question.reponses[0].valeur : '',
            autres: this.props.answered ? this.props.question.reponses[0].saisie_libre : '',
            edition: false
        });
    }

    submitAnswer = () => {
        const responseId = this.state.responsesList.filter(r => r.libelle === this.state.answer)[0].id;
        if (this.props.answered || this.props.question.is_reponse_expired) {
            AnswersService.updateAnswer(this.props.question, this.state.answer, this.state.autres, responseId).then(() => {
                this.setState({ showAlertSuccess: true });
                projetsService.getProjetById(this.props.idProjet).then((resp) => {
                    const questions = utilsService.extractActiveQstsWithCategories(resp.data[0].noeuds);
                    this.props.initQuestions(questions);
                    this.setState({
                        edition: false
                    });
                    this.props.showAlert({
                        type: 'success',
                        message: <Translation>{(t) => <p>{t('REPONSE_MODIFIEE')}</p>}</Translation>
                    });
                });
            })
        } else {
            AnswersService.postAnswer(this.props.question, this.state.answer, this.state.autres, responseId).then(() => {
                this.setState({ isOpenSubmit: false, showAlertSuccess: true, answer: '' });
                projetsService.getProjetById(this.props.idProjet).then((resp) => {
                    const questions = utilsService.extractActiveQstsWithCategories(resp.data[0].noeuds);
                    this.props.initQuestions(questions);
                    this.setState({
                        answer: '',
                        autres: '',
                        responsesList: [],
                        edition: false
                    });
                    this.props.showAlert({
                        type: 'success',
                        message: <Translation>{(t) => <p>{t('REPONSE_ENREGISTREE')}</p>}</Translation>
                    });
                });
            });
        }
    }

    editAnswer = () => {
        this.setState({
            edition: true,
        });
    }

    deleteAnswer = () => {
        AnswersService.deleteAnswer(this.props.question.id, this.props.question.noeud_id).then(() => {
            this.setState({ showAlertSuccess: true, edition: false });
            projetsService.getProjetById(this.props.idProjet).then((resp) => {
                const questions = utilsService.extractActiveQstsWithCategories(resp.data[0].noeuds);
                this.props.initQuestions(questions);
                this.props.showAlert({
                    type: 'success',
                    message: <Translation>{(t) => <p>{t('REPONSE_SUPPRIMEE')}</p>}</Translation>
                });
            });
        })
    }

    getButtons() {
        let buttons;
        if (this.props.answered && !this.state.edition) {
            buttons = <div className="col-4 pr-0 text-right">
                <Button className="margin-bottom-10" action="importante" onClick={this.editAnswer} > <Translation>{(t) => <p>{t('MODIFIER')}</p>}</Translation> </Button>
                <Button className="margin-top-10" action="suppression" onClick={this.deleteAnswer} > <Translation>{(t) => <p>{t('SUPPRIMER')}</p>}</Translation> </Button>
            </div>;
        } else if (this.props.isDirection) {
            buttons = <div className="col-4 pr-0 text-right">
                <ModificationQuestion question={this.props.question} idProjet={this.props.idProjet} showAlert={this.props.showAlert} setQuestionModify={this.props.setQuestionModify} />
                <Button className="margin-top-10" action="suppression" block variant="outline-danger" onClick={() => this.props.deleteAnswerDirection(this.props.question.id)} > <Translation>{(t) => <p>{t('SUPPRIMER')}</p>}</Translation> </Button>
            </div>;
        } else {
            buttons = <div className="col-4 pr-0 text-right">
                <Button className="margin-bottom-10" action="importante" onClick={this.submitAnswer} disabled={!this.state.answer} > <Translation>{(t) => <p>{t('VALIDER')}</p>}</Translation> </Button>
                <Button className="margin-top-10" action="simple" onClick={this.cancelAnswer} disabled={this.props.answered ? this.state.answer === this.props.question.reponses[0].valeur : !this.state.answer} > <Translation>{(t) => <p>{t('ANNULER')}</p>}</Translation> </Button>
            </div>;
        }

        return buttons;
    }

    render() {
        return (
            <>
                <Form ref={form => this.form = form}>
                    <div className="row mx-0">
                        <div className="col-8 pl-0">
                            {!this.props.isDirection ? (
                                <div className="form-group">
                                    <select required className="custom-select form-control" value={this.props.answered && !this.state.edition ? this.props.question.reponses[0].valeur : this.state.answer} onChange={this.handleChangeSelect} disabled={(this.props.answered && !this.state.edition)}>
                                        <option className="custom-options-default" value="" disabled selected hidden>{this.props.t('SELECTIONNER_REPONSE')}</option>
                                        {
                                            this.state.responsesList
                                                && this.state.responsesList.length > 0 ?
                                                this.state.responsesList.map((valeur, index) => {
                                                    return (
                                                        <option className="custom-options" key={index} value={valeur.libelle} >{valeur.libelle}</option>
                                                    )
                                                }) : ''
                                        }
                                    </select>
                                    {this.state.answer === 'Autres' ? <Form.Group controlId="answerInput">
                                        <Form.Label class="mt-2"><Translation>{(t) => <p>{t('PRECISEZ')}</p>}</Translation></Form.Label>
                                        <Form.Control as="textarea" rows="3" value={this.state.autres} onChange={this.handleChangeInputText} disabled={(this.props.answered && !this.state.edition)} />
                                    </Form.Group> : ''}
                                </div>) :
                                <div className="d-flex flex-wrap" >
                                        <div className="my-1">
                                            <div className="text-information">
                                                <p><Translation>{(t) => <b>{t('TYPE_DE_REPONSE')}</b>}</Translation><Translation>{(t) => <>{t('LISTE_DEROULANTE')}</>}</Translation> </p>
                                            </div>
                                        </div>
                                    {this.state.answersPercent.valeursQuestions.map(valeur => {
                                        return (
                                            <div className="col-12 pl-0">
                                                <div className="col-6 pl-0">
                                                    <div className="d-flex border-information my-1">
                                                        <div className="col-9">
                                                            {valeur.libelle}
                                                        </div>
                                                        <div className="col-3 text-right">
                                                            {this.state.answersPercent.nbAnswers === 0 ? 0 : (valeur.nbAnswer / this.state.answersPercent.nbAnswers * 100).toFixed(0)}%
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>}
                        </div>

                        {this.getButtons()}

                    </div>
                </Form>
            </>
        );
    }
}

const ChoixSimpleAnswerContainer = connect(mapStateToProps, mainScreenAction)(ChoixSimpleAnswer);

export default withTranslation()(ChoixSimpleAnswerContainer);