import http from "../http-common.js";

class SignalementsService {

    postSignalement(signalement) {
      return http.post(`/signalements/`, signalement);
    }

  }
  
  export default new SignalementsService();