import React, { Component } from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { ReactComponent as FlecheDroiteLogo } from '../../../svg/fleche-droite-circle.svg';
import "./projet-item.css";
import { Translation } from 'react-i18next';

class ProjetItem extends Component {

  render() {
    var projet = this.props.projet;
    var nbrQuestions = 0;
    var nbrQuestionsCompletees = 0;
    var listWithReponses = [];
    var listWithQuestionOutdated = [];

    projet.noeuds.forEach(noeud => {
      noeud.categories.forEach(categorie => {
        categorie.questions.forEach(question => {
          if (question.statut) {
            question.reponses.forEach(reponse => {
              if (reponse.id_noeud === noeud.id && listWithReponses.indexOf(reponse.id_question) === -1) {
                listWithReponses.push(reponse.id_question);
                if (question.is_reponse_expired) {
                  listWithQuestionOutdated.push(question)
                }
              }
            })
          }
        })
        nbrQuestions = nbrQuestions + (categorie.questions ? categorie.questions.filter(qst => !!qst.statut).length : 0);
      })
    });


    nbrQuestionsCompletees = listWithReponses.length;
    var nbrQuestionsNonRepondues = nbrQuestions - nbrQuestionsCompletees;

    let percentQuestionsRepondues = nbrQuestionsCompletees * 100 / nbrQuestions;
    let percentQuestionsNonRepondues = 100 - percentQuestionsRepondues;
    var percentQuestionOutdated = nbrQuestionsCompletees > 0 ? listWithQuestionOutdated.length * 100 / nbrQuestionsCompletees : 0;

    return (
      <div className="row mx-0">
        <div className="col-md-5 px-0 no-padding custom-self-center">
          <label className="nom-projet my-0"><p class="my-0">{projet.libelle}</p></label><br />
          <label className="custom-label-rapport"><h3 class="my-0">{nbrQuestionsCompletees}/{nbrQuestions}
            <Translation>{(t) => <p>{t('QUESTIONS_REPONDUES_MIN')}</p>}</Translation></h3></label>

          <ProgressBar className="item-progression">
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip className="tooltip-bg-white" >
                  <div className="row">
                    <p className="text-success"><b> {percentQuestionsRepondues.toFixed(0)} <Translation>{(t) => t('POURCENTAGE_REPONDUES')}</Translation> </b></p>
                  </div>
                  <div className="row">
                    <p className="text-danger"><b><Translation>{(t) => t('POURCENTAGE_PERIMEES_START')}</Translation>{percentQuestionOutdated.toFixed(0)} <Translation>{(t) => t('POURCENTAGE_PERIMEES_END')}</Translation> </b></p>
                  </div>
                </Tooltip>
              }
            >
              <ProgressBar isChild={true} key={projet.id + 'answered'} now={percentQuestionsRepondues.toFixed(0)} variant="success"></ProgressBar>
            </OverlayTrigger>
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip className="tooltip-bg-white" >
                  <p className="text-information"><b> {percentQuestionsNonRepondues.toFixed(0)} <Translation>{(t) => t('POURCENTAGE_NON_REPONDUES')}</Translation> </b></p>
                </Tooltip>
              }
            >
              <ProgressBar isChild={true} key={projet.id + 'notanswered'} now={percentQuestionsNonRepondues.toFixed(0)} className="progress-bar-bg-grey"></ProgressBar>
            </OverlayTrigger>

          </ProgressBar>
        </div>
        <div className="col-md-6 custom-self-center delete-padding">
          <div className="row align-items-center h-100">
            <div className="col-2 alignement-nbr-orange delete-padding">
              <label className="custom-label-nbr-orange mb-0">{nbrQuestionsNonRepondues}</label>
            </div>
            <div className="col-10 delete-padding">
              <label className="custom-label-question-en-attente-de-reponse mb-0 pl-3">
                <Translation>{(t) => <p>{t('QUESTIONS_EN_ATTENTE_DE_REPONSE_MIN_1')}</p>}</Translation><br />
                <Translation>{(t) => <p>{t('QUESTIONS_EN_ATTENTE_DE_REPONSE_MIN_2')}</p>}</Translation></label>
            </div>
          </div>
        </div>

        <div className="col-md-1 p-0 custom-self-center text-right">
          <FlecheDroiteLogo fill="#00aa9b" width="40" />
        </div>
      </div >
    );
  }
}

export default ProjetItem;