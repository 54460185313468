import React, { useState } from 'react';
import i18n from '../../i18n';
import { withTranslation } from 'react-i18next';
import { Dropdown } from 'react-bootstrap';
import DrapeauFr from '../../logo/drapeau-fr.png';
import DrapeauEn from '../../logo/drapeau-en.png';
import "./navbar.css";
import { Translation } from 'react-i18next';

function ManageInternationalisation({ t }) {

    // indicateur est une variable permettant de savoir en quelle langue nous sommes : true si on est en francais, false si on est en anglais
    const [indicateur, setIndicateur] = useState(true);

    const changeLanguage = (lng) => {
        if (lng === "fr") {
            setIndicateur(true);
        }
        else {
            setIndicateur(false);
        }
        i18n.changeLanguage(lng);
    }
    // icon-flag
    return (
        <div>
            {indicateur ?
                <Dropdown.Item onClick={() => changeLanguage('en')} className="dropdown-custom">
                    <img className="icon-flag" src={DrapeauEn} />
                    <Translation>{(t) => <p>{t('ANGLAIS')}</p>}</Translation>
                </Dropdown.Item>
                :

                <Dropdown.Item onClick={() => changeLanguage('fr')} className="dropdown-custom">
                    <img className="icon-flag" src={DrapeauFr} />
                    <Translation>{(t) => <p>{t('FRANCAIS')}</p>}</Translation>
                </Dropdown.Item>
            }
        </div>
    )
}

export default withTranslation()(ManageInternationalisation);