import React from "react";
import { Form } from "react-bootstrap";
import Button from '../custom_button/custom_button'
import { connect } from "react-redux";
import { mainScreenAction } from "../../actions/MainScreenActions";
import { mapStateToProps } from "../../selectors/MainScreenSelector";
import projetsService from "../../services/projets.service";
import AnswersService from "../../services/reponse.service";
import { RadioGroup, Radio, FormControlLabel, FormControl } from '@material-ui/core';
import utilsService from "../../services/utils.service";
import "./answers.css";
import { Translation } from 'react-i18next';
import ModificationQuestion from "../modificationQuestion/modificationQuestion.component";

class RadioButtonAnswer extends React.Component {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.state = {
            showAlertSuccess: false,
            answer: '',
            defaultValue: '',
            isAutreActif: false,
            isValidationButtonActif: false,
            valueRadioBouton: this.props.answered ? this.props.question.reponses[0].valeur : '',
            edition: false,
            answersPercent: utilsService.getOccurenceAnswers(this.props.question)
        };
        this.handleChangeInputText = this.handleChangeInputText.bind(this);
        this.getRowValeursQuestions = this.getRowValeursQuestions.bind(this);
    }

    componentWillReceiveProps(props) {
        if (props.answered && props.question.reponses !== this.props.question.reponses) {
            this.setState({
                valueRadioBouton: props.answered ? props.question.reponses[0].valeur : ''
            })
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.question !== this.props.question) {
            this.setState({
                answersPercent: utilsService.getOccurenceAnswers(this.props.question)
            })
        }
    }

    getRowValeursQuestions() {
        const rows = [];
        const valeursQuestions = this.props.question && this.props.question.valeurs_questions ?
            this.props.question.valeurs_questions : [];
        valeursQuestions.forEach((valeurQuestion, index) => {
            if (valeurQuestion.libelle === "Autres") {
                rows.push(
                    <div className="radio-button-autre" key={index}>
                        <FormControlLabel classes={{label: 'custom-label-radio'}} key={'label' + index} value={valeurQuestion.libelle} control={<Radio classes={{root: 'custom-circle-radio'}} key={'radio' + index} disabled={(this.props.answered && !this.state.edition)} />} label={valeurQuestion.libelle} />
                        <Form.Group controlId="TagsInput">
                            <Form.Label><Translation>{(t) => <p>{t('PRECISEZ')}</p>}</Translation></Form.Label>
                            <Form.Control as="textarea" rows="3" disabled={!this.state.isAutreActif} value={this.props.answered && !this.state.edition ? this.props.question.reponses[0].saisie_libre : this.state.answer} onChange={this.handleChangeInputText} />
                        </Form.Group>
                    </div>
                );
            }
            else {
                rows.push(<FormControlLabel classes={{label: 'custom-label-radio'}} key={'label' + index} value={valeurQuestion.libelle} control={<Radio classes={{root: 'custom-circle-radio'}} key={'radio' + index} />} label={valeurQuestion.libelle} disabled={(this.props.answered && !this.state.edition)} />);
            }
        });
        return rows;
    }

    handleChangeInputText(e) {
        if (e.target.value) {
            this.setState({
                answer: e.target.value,
                isValidationButtonActif: true
            })
        }
        else {

            this.setState({
                isValidationButtonActif: false,
                answer: e.target.value
            })
        }
    }

    clearForm = () => {
        this.setState({
            isOpenCancel: false, answer: '',
            valueRadioBouton: this.props.answered ? this.props.question.reponses[0].valeur : '',
            isValidationButtonActif: false,
            edition: false,
            isAutreActif: false
        });
    }

    hideAlertSuccess = () => this.setState({ showAlertSuccess: false });

    submitAnswer = () => {

        const valeurQuestionId = this.props.question.valeurs_questions.filter(r => r.libelle === this.state.valueRadioBouton)[0].id;
        if (this.props.answered || this.props.question.is_reponse_expired) {
            AnswersService.updateAnswer(this.props.question, this.state.valueRadioBouton, this.state.answer, valeurQuestionId).then(() => {
                projetsService.getProjetById(this.props.idProjet).then((resp) => {
                    const questions = utilsService.extractActiveQstsWithCategories(resp.data[0].noeuds);
                    this.props.initQuestions(questions);
                    this.setState({ showAlertSuccess: true });
                    this.setState({
                        isAutreActif: false,
                        isValidationButtonActif: false,
                        edition: false
                    });
                    this.props.showAlert({
                        type: 'success',
                        message: <Translation>{(t) => <p>{t('REPONSE_MODIFIEE')}</p>}</Translation>
                    });
                });
            })

        } else {
            AnswersService.postAnswer(this.props.question, this.state.valueRadioBouton, this.state.answer, valeurQuestionId).then(() => {
                projetsService.getProjetById(this.props.idProjet).then((resp) => {
                    const questions = utilsService.extractActiveQstsWithCategories(resp.data[0].noeuds);
                    this.props.initQuestions(questions);
                    this.setState({ isOpenSubmit: false, showAlertSuccess: true, answer: '' });
                    this.setState({
                        answer: '',
                        autres: '',
                        valueRadioBouton: '',
                        isValidationButtonActif: false
                    });
                    this.props.showAlert({
                        type: 'success',
                        message: <Translation>{(t) => <p>{t('REPONSE_ENREGISTREE')}</p>}</Translation>
                    });
                });
            })
        }
    }

    handleChange(event) {
        if (event.target.value === "Autres") {
            this.setState({
                isAutreActif: true,
                valueRadioBouton: event.target.value,
                isValidationButtonActif: false
            })
        }
        else {
            this.setState({
                isValidationButtonActif: true,
                isAutreActif: false,
                valueRadioBouton: event.target.value,
                answer: ''
            });
        }

    };

    editForm = () => {
        this.setState({
            edition: true,
            answer: this.props.answered ? this.props.question.reponses[0].saisie_libre : this.state.answer,
            isAutreActif: this.props.question.reponses[0].valeur === 'Autres'
        });
    }

    deleteAnswer = () => {
        AnswersService.deleteAnswer(this.props.question.id, this.props.question.noeud_id).then(() => {
            this.setState({ showAlertSuccess: true, edition: false });
            projetsService.getProjetById(this.props.idProjet).then((resp) => {
                const questions = utilsService.extractActiveQstsWithCategories(resp.data[0].noeuds);
                this.props.initQuestions(questions);
                this.props.showAlert({
                    type: 'success',
                    message: <Translation>{(t) => <p>{t('REPONSE_SUPPRIMEE')}</p>}</Translation>
                });
            });
        })
    }

    getButtons() {
        let buttons;
        if (this.props.answered && !this.state.edition) {
            buttons = <div className="col-4 pr-0 text-right">
                <Button className="margin-bottom-10" action="importante" onClick={this.editForm} > <Translation>{(t) => <p>{t('MODIFIER')}</p>}</Translation> </Button>
                <Button className="margin-top-10" action="suppression" onClick={this.deleteAnswer} > <Translation>{(t) => <p>{t('SUPPRIMER')}</p>}</Translation> </Button>
            </div>;
        } else if (this.props.isDirection) {
            buttons = <div className="col-4 pr-0 text-right">
                <ModificationQuestion className="margin-bottom-10" question={this.props.question} idProjet={this.props.idProjet} showAlert={this.props.showAlert} setQuestionModify={this.props.setQuestionModify} />
                <Button className="margin-top-10" action="suppression" block variant="outline-danger" onClick={() => this.props.deleteAnswerDirection(this.props.question.id)} > <Translation>{(t) => <p>{t('SUPPRIMER')}</p>}</Translation> </Button>
            </div>;
        } else {
            buttons = <div className="col-4 pr-0 text-right">
                <Button className="margin-bottom-10" action="importante" onClick={this.submitAnswer} disabled={!this.state.isValidationButtonActif} > <Translation>{(t) => <p>{t('VALIDER')}</p>}</Translation> </Button>
                <Button className="margin-top-10" action="simple" onClick={this.clearForm} disabled={!this.state.isValidationButtonActif} > <Translation>{(t) => <p>{t('ANNULER')}</p>}</Translation> </Button>
            </div>;
        }

        return buttons;
    }

    render() {
        return (
            <>
                <FormControl>
                    <div className="d-flex align-items-center">
                        <div className="col-8 pl-0">
                            <RadioGroup value={this.state.valueRadioBouton} onChange={this.handleChange}>

                                {!this.props.isDirection ? (this.getRowValeursQuestions()) :
                                    <div className="row mx-0" >
                                            <div className="my-1">
                                                <div className="text-information">
                                                    <p><Translation>{(t) => <b>{t('TYPE_DE_REPONSE')}</b>}</Translation><Translation>{(t) => <>{t('RADIO_BOUTON')}</>}</Translation> </p>
                                                </div>
                                            </div>
                                        {this.state.answersPercent.valeursQuestions.map((valeur, index) => {
                                            return (
                                                <div className="col-12 pl-0" key={index}>
                                                    <div className="col-6 pl-0">
                                                        <div className="d-flex border-information my-1">
                                                            <div className="col-9">
                                                                {valeur.libelle}
                                                            </div>
                                                            <div className="col-3 text-right">
                                                                {this.state.answersPercent.nbAnswers === 0 ? 0 : (valeur.nbAnswer / this.state.answersPercent.nbAnswers * 100).toFixed(0)}%
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>

                                }

                            </RadioGroup>
                        </div>

                        {this.getButtons()}
                    </div>
                </FormControl>

            </>
        );
    }
}

const RadioButtonAnswerContainer = connect(mapStateToProps, mainScreenAction)(RadioButtonAnswer);

export default RadioButtonAnswerContainer;
