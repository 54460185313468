const init_state = []

const INIT_QUESTIONS = 'INIT_QUESTIONS';

export default function questions (state = init_state, action) {

    switch (action.type) {
        case INIT_QUESTIONS:
            return action.questions;
            default:
            return state;
    }
};



