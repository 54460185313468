

import React, { Component } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Slide from '@material-ui/core/Slide';

class SnakeBarComponent extends Component {
    render() {
        return (
            <Snackbar
                open={this.props.open}
                autoHideDuration={3000}
                TransitionComponent={Slide}
                onClose={this.props.hide}
                anchorOrigin={ { vertical : 'top', horizontal : 'center'}}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    severity={this.props.severity}
                    onClose={this.props.hide}
                >
                    {this.props.message}
                </MuiAlert>
            </Snackbar>
        );
    }

}
export default SnakeBarComponent;
