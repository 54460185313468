import React, { Component } from "react";
import "./navbar.css";
import { ReactComponent as ClochePleine } from '../../svg/cloche-pleine.svg';
import LogoMyPrism from '../../logo/logo-myPrism.png';
import { Dropdown } from 'react-bootstrap';
import { connect } from "react-redux";
import { mapStateToProps } from '../../selectors/MainScreenSelector';
import { mainScreenAction } from '../../actions/MainScreenActions';
import ManageInternationalisation from "./manageInternationalisation.component"
import { Translation } from 'react-i18next';
import { ReactComponent as LogoInetumBlanc } from '../../svg/logo-inetum-blanc.svg';
import { Auth } from 'aws-amplify';

class NavbarComponent extends Component {

    handleDeconnexion = async () => {
      try {
          await Auth.signOut();
          sessionStorage.clear();
          window.open("/","_self");
      } catch (error) {
          console.log('error signing out: ', error);
      }
  }

  render() {

  const connected =  sessionStorage.getItem('loggedin');
    return (
      <div className="border-custom">

        <nav className="navbar navbar-expand navbar-custom">
          <div className="col-2 col-md-6 d-md-block">
            <LogoInetumBlanc className="mr-4 d-none d-md-inline-block" />
            <img src={LogoMyPrism} className="logo-myPrism" />
          </div>
          
          <div className="col-10 col-md-6 notification-custom align-right-custom">
            <div className="float-right bloc-account">
              <Dropdown className="align-right-custom">
                <Dropdown.Toggle className="dropdown-custom" variant="secondary" id="dropdown-basic">
                  {this.props.currentUser && connected === 'true' ? <span className="account-infos">{this.props.currentUser.prenom} {this.props.currentUser.nom}</span> : <Translation>{(t) => <p>{t('MON_ESPACE')}</p>}</Translation>}
                </Dropdown.Toggle>

                <Dropdown.Menu className="dropdown-custom">
                  <ManageInternationalisation />
                  {connected === 'true' ? <Dropdown.Item  className="dropdown-custom" onClick={this.handleDeconnexion}>
                    <Translation>{(t) => <p>{t('DECONNEXION')}</p>}</Translation>
                  </Dropdown.Item> : ''}
                </Dropdown.Menu>
              </Dropdown>
              </div>
              <div className="float-right bloc-notifs mr-4">
              <ClochePleine height="24px" width="24px" />
              <label className="label-notification"> 0
                      <Translation>{(t) => <span>{t('NOTIFICATIONS')}</span>}</Translation>
              </label>
            </div>
          </div>
        </nav>
      </div>
    );
  }
}

const Navbar = connect(mapStateToProps, mainScreenAction)(NavbarComponent);

export default Navbar;
