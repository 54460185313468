import React from 'react';
import "./custom_button.css";

const actionsWithStyle = {
    importante: 'btn-important',
    simple:'btn-simple',
    suppression: 'btn-delete',
    enregistrement: 'btn-enregistrement',
    showMore: 'btn-show-more'
  };

export const Button =({
    action,
    children,
    onClick,
    className,
    disabled
    }) => {
    const getClassFromAction = actionsWithStyle[action];
    return (
        <button type="button" className={`custom-btn ${getClassFromAction} ${className}`} onClick={onClick} disabled={disabled}>{children}</button>
    );
}

export default Button;