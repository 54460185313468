import React, { Component } from 'react';
import { Button, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { Translation, withTranslation } from 'react-i18next';
import './modalReponseExpiree.css';
import { connect } from 'react-redux';
import { mapStateToProps } from '../../../../selectors/MainScreenSelector';
import { mainScreenAction } from '../../../../actions/MainScreenActions';
import ReponseService from "../../../../services/reponse.service";
import ModificationQuestion from "../../../modificationQuestion/modificationQuestion.component"
import CreateQuestionModalComponent from "../../../createquestion/createquestion.modal"

class ModalReponseExpireeComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataReady: false,
            showModalModification: false
        }

        this.getInfoReponses = this.getInfoReponses.bind(this);
    }

    componentDidMount() {
        this.getInfoReponses();
    }

    hide = () => {
        this.props.onHide();

    }

    showModalModification = () => {
        this.setState({
            showModalModification: true
        })
    }

    hideModalModification = (isSubmit) => {
        this.setState({
            showModalModification: false
        });
        if (isSubmit) {
            this.hide();
        }
    }

    getInfoReponses() {
        if (this.props.mode === 'PERIMEES') {
            ReponseService.getInfoReponseExpirees(this.props.question.id).then((resp) => {
                console.log(resp);
                this.setState({
                    infos: resp.data,
                    dataReady: true
                })
            });
        }

    }

    displayInfoSignalements() {
        let rows = [];
        if (this.props.mode === 'SIGNALEES') {
            this.props.question.signalements.map((signalement, index) => {
                const dateAjout = new Date(signalement.date_maj);
                const month = dateAjout.getMonth() + 1;
                const dateToDisplay = dateAjout.getDate() + "/" + month + "/" + dateAjout.getFullYear();
                const hourToDisplay = dateAjout.getHours() + ":" + dateAjout.getMinutes();

                rows.push(<div key={index} className="bg-contrast-white mx-4 py-4 mb-2">
                    <h3 className="custom-title-reponse col">{signalement.projets_ref.libelle} - {signalement.user.nom} {signalement.user.prenom}</h3>
                    <div className="mt-2 font-weight-bold custom-display-flex">
                        <div className="text-left col">
                            <Translation>{(t) => <p>{t('QUESTION_SIGNALEE_LE')}</p>}</Translation> {dateToDisplay} - {hourToDisplay}
                        </div>
                    </div>
                    <div className="col mt-2 text-break">
                        <p className="col">{signalement.commentaire}</p>
                    </div>

                </div>)
            })
        } else {
            rows.push(<div key={'chargement-key'} className="bg-contrast-white mx-4 py-4"> <span className="pl-2"> <Translation>{(t) => <p className="col">{t('CHARGEMENT')}</p>}</Translation></span></div>);
        }
        return rows;
    }

    displayInfoReponses() {
        let rows = [];
        if (this.state.dataReady) {
            this.state.infos.map((info, index) => {
                const dateAjout = new Date(info.dateReponseEnregistree);
                const month = dateAjout.getMonth() + 1;
                const dateToDisplay = dateAjout.getDate() + "/" + month + "/" + dateAjout.getFullYear();

                const dateExpiration = new Date(info.dateExpiration);
                const nbrDays = Math.round((new Date().getTime() - dateExpiration.getTime()) / (1000 * 60 * 60 * 24));

                rows.push(<div key={index} className="bg-contrast-white mx-4 py-4 mb-2">
                    <h3 className="custom-title-reponse col">{info.nomProjet} - {info.infoUser}</h3>
                    <div className="custom-display-flex">
                        <div className="text-left col">
                            <Translation>{(t) => <p>{t('REPONSE_ENREGISTREE_LE')}</p>}</Translation> {dateToDisplay}
                        </div>

                        <div className="text-right col text-danger">
                            <Translation>{(t) => <p>{t('REPONSES_PERIMEES_DEPUIS')}</p>}</Translation> {nbrDays} <Translation>{(t) => <p className="pt-2">{t('JOURS')}</p>}</Translation>
                        </div>
                    </div>
                    <span className="col">{info.value}</span>
                </div>)
            })
        } else {
            rows.push(<div key={'chargement-key'} className="bg-contrast-white mx-4 py-4"> <span className="pl-2"> <Translation>{(t) => <p className="col">{t('CHARGEMENT')}</p>}</Translation></span></div>);
        }
        return rows;
    }

    render() {

        return <>
         {this.state.showModalModification ? <CreateQuestionModalComponent
                                            show={this.state.showModalModification}
                                            onHide={this.hideModalModification}
                                            question={this.props.question}
                                            idProjet={this.props.idProjet}
                                            showAlert={this.props.showAlert}
                                            setQuestionModify={this.props.setQuestionModify}
                                            isFromModalSignalement={1}>
                                        </CreateQuestionModalComponent> : ""}
        <div onClick={e => e.stopPropagation()}>
            <Modal show={this.props.show && !this.state.showModalModification} onHide={this.hide} size="xl" backdrop="static" onClick={e => e.stopPropagation()}>
                <Modal.Header className="flex-column align-items-center custom-header">
                    <div className="row custom-justify-content">
                        <Button onClick={this.hide} className="btn-outline text-important rounded-pill" >
                            <FontAwesomeIcon icon={faChevronLeft} /> <Translation>{(t) => <p>{t('RETOUR')}</p>}</Translation>
                        </Button>
                    </div>
                    <div className="row pt-2">
                        <div className="col custom-align-center">
                            <b><h1 className="text-important"><Translation>{(t) => <p>{this.props.mode === 'PERIMEES' ? t('DETAILS_REPONSES_PERIMEES_SUR_QUESTION') : this.props.mode === 'SIGNALEES' ? t('DETAILS_REPONSES_SIGNALEES_SUR_QUESTION') : ''}</p>}</Translation></h1></b>
                            <h2 className="pt-2">{this.props.question.libelle}</h2>
                            {this.props.mode === 'PERIMEES' ?
                                <h3 className="pt-2"><Translation>{(t) => <p>{t('DUREE_DE_VALIDITE_DE')}</p>}</Translation> {this.props.question.duree_validite} <Translation>{(t) => <p>{t('MOIS')}</p>}</Translation></h3>
                                : <div className="row custom-justify-content">
                                    <div className="col-3">
                                        <Button block variant="warning" onClick={this.showModalModification}> <Translation>{(t) => <p>{t('MODIFIER')}</p>}</Translation> </Button>
                                    </div>
                                </div>


                            }
                        </div>
                    </div>
                </Modal.Header>

                <Modal.Body>
                    {this.props.mode === 'PERIMEES' ? this.displayInfoReponses() : this.props.mode === 'SIGNALEES' ? this.displayInfoSignalements() : ''}
                </Modal.Body>
            </Modal>
        </div>
        
        </>
    }
}

const ModalReponseExpiree = connect(mapStateToProps, mainScreenAction)(ModalReponseExpireeComponent);

export default withTranslation()(ModalReponseExpiree);