import axios from "axios";
require('dotenv').config();

var HOST = '';
if(process.env.NODE_ENV === 'development'){
  HOST = 'http://localhost:8080';
}

const env = process.env.REACT_APP_SERVER_ENV;

if(env === 'dev'){
  HOST = 'https://my-project-prism-api.dev.cloud-gfi-nord.fr';
}else if(env === 'qua'){
  HOST = 'https://my-project-prism-api.rec.cloud-gfi-nord.fr';
}


export default axios.create({
  baseURL:  HOST +'/api',
  headers: {
    "Content-type": "application/json",
    "Cache-Control": "no-cache"
  }
});

