export const mainScreenAction = (dispatch) => {
    return {
        initQuestions : (questions) => {
                dispatch({
                    type:'INIT_QUESTIONS',
                    questions: questions
                });
        },
        initProjets : (projets) => {
            dispatch({
                type:'INIT_PROJETS',
                projets: projets
            });
        },
        initCategories : (categories) => {
            dispatch({
                type:'INIT_CATEGORIES',
                categories: categories
            });
        },
        initCurrentUser : (currentUser) => {
            dispatch({
                type:'INIT_CURRENT_USER',
                currentUser: currentUser
            });
        },
        handleFilterRep : (filter) => {
            dispatch({
                type:'UPDATE_FILTER_REP',
                filter: filter
            });
        },
        handleFilterNonRep : (filter) => {
            dispatch({
                type:'UPDATE_FILTER_NON_REP',
                filter: filter
            });
        }

    }
}