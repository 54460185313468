import http from "../http-common.js";

class QuestionsService {

    getQuestions(state) {
      return http.get(`/questions/statut/`+state);
    } 

    postQuestion(question) {
      return http.post(`/questions/`, question);
    }

    putQuestionStatut(idQuestion,statut){
      return http.put('/questions/'+ idQuestion+'/statut', {statut : statut});
    }

    updateQuestion(question) {
      return http.put('/questions/modification', question);
    }

     deleteQuestion(id) {
      return http.delete('/questions/direction/'+ id);
    }
  }
  
  export default new QuestionsService();