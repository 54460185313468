import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./accueil.css";
import UsersDataService from "../../services/users.service";
import { RadialChart } from 'react-vis';
import { connect } from 'react-redux';
import { mapStateToProps } from '../../selectors/MainScreenSelector';
import { mainScreenAction } from "../../actions/MainScreenActions";
import CategoriesService from '../../services/categories.service';
import { Translation } from 'react-i18next';
import ExportService from '../../services/export.service'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CreateQuestionModalComponent from '../createquestion/createquestion.modal';
import UsersService from "../../services/users.service";
import UtilsService from "../../services/utils.service";
import CircularProgress from '@material-ui/core/CircularProgress';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare } from '@fortawesome/free-solid-svg-icons';
import { AutoSizer } from 'react-virtualized';

class accueilDirecteurcomponent extends Component {

  constructor(props) {
    super(props);
    this.findUser = this.findUser.bind(this);
    this.findCategories = this.findCategories.bind(this);

    this.state = {
      user: null,
      nombreResponsesQst: 0,
      pourcentageNonRep: 0,
      pourcentageRep: 0,
      pourcentagePerimees: 0,
      myData: [],
      myData1: [],
      myData1graphe: [],
      sumMyData1: 0,
      under25: 0,
      under50: 0,
      under75: 0,
      more75: 0,
      activesNbr: 0,
      inactivesNbr: 0,
      signalementsNbr: 0,
      initDataDone: false
    };
  }

  componentDidMount() {
    this.findUser();
    this.findCategories();
    this.initIndicators();
  }

  initIndicators() {
    UsersService.getUserCategoriesById(sessionStorage.getItem('user')).then(categories => {
      const indicators = UtilsService.getResponseIndicatortsForDirector(categories.data);
      const indicatorsActiveInactiveAndSignalement = UtilsService.extractIndicatorForActiveAndInactiveQstsAndSignalements(categories.data);
      this.setState({
        nbNonRep: indicators.nbNonRep,
        nbRep: indicators.nbRep,
        nbPerimees: indicators.nbPerimees,
        activesNbr: indicatorsActiveInactiveAndSignalement.actives,
        inactivesNbr: indicatorsActiveInactiveAndSignalement.inactives,
        signalementsNbr: indicatorsActiveInactiveAndSignalement.signalements,
        categoriesData: categories.data
      });
    }).then(() => {
      UsersService.getDirectorProjectsById(sessionStorage.getItem('user')).then(projets => {
        const indicators = UtilsService.getIndecatorsProjectsForDirector(projets.data, this.state.categoriesData);
        let myData1 = [
          {
            angle: indicators.under25,
            color: '#F96B1A',
            libelle: 'MOINS_25'
          },
          {
            angle: indicators.under50,
            color: '#F9EC3F',
            libelle: 'ENTRE_26_50'
          },
          {
            angle: indicators.under75,
            color: '#6B41F9',
            libelle: 'ENTRE_51_75'
          },
          {
            angle: indicators.more75,
            color: '#16DE4B',
            libelle: 'PLUS_76'
          }];
        let sumMyData1 = 0;
        myData1.forEach(data => {
          sumMyData1 += data.angle
        })
        this.setState({
          nombreResponsesQst: indicators.nbrQstsRepAll,
          nbTotalQuestions: indicators.nbrQstsTotal,
          pourcentageNonRep: !!indicators.nbrQstsTotal ? ((indicators.nbNonRep * 100 / indicators.nbrQstsTotal)).toFixed(0) : 0,
          pourcentageRep: !!indicators.nbrQstsTotal ? ((indicators.nbrQstsRepAll * 100 / indicators.nbrQstsTotal)).toFixed(0) : 0,
          pourcentagePerimeesGraphe: indicators.nbrQstsRepAll === 0 || indicators.nbrQstsTotal === 0 ? 0 : ((indicators.nbrQstPerimees * ((indicators.nbrQstsRepAll / indicators.nbrQstsTotal)) / indicators.nbrQstsRepAll) * 100).toFixed(0),
          pourcentagePerimees: indicators.nbrQstsRepAll === 0 ? 0 : ((indicators.nbrQstPerimees * 100 / indicators.nbrQstsRepAll)).toFixed(0),
          myData1: myData1,
          sumMyData1: sumMyData1,
          myData1graphe: myData1.map(data => { return { angle: data.angle, color: data.color } }),

        });
        this.setState({
          myData: [{
            angle: !!(this.state.pourcentageRep - this.state.pourcentagePerimeesGraphe) ?
              (this.state.pourcentageRep - this.state.pourcentagePerimeesGraphe) : 0,
            color: '#3ABF35'
          }, {
            angle: !!this.state.pourcentageNonRep ? Number(this.state.pourcentageNonRep) : 0,
            color: '#232C4B'
          },
          {
            angle: !!this.state.pourcentagePerimeesGraphe ? Number(this.state.pourcentagePerimeesGraphe) : 0,
            color: '#F52547'
          }]
        }
        )
      });
    }).then(() => {
      this.setState({
        initDataDone: true,
      });
    });
  }

  findCategories() {
    CategoriesService.getCategories().then(
      response => {
        this.props.initCategories(response.data);
        let mapDataQuestionsCategories = new Map();
        response.data.map(categorie => mapDataQuestionsCategories.set(categorie.code, { nbrQuestionsNonRepondues: 0, categorieCouleur: categorie.color, categorieLibelle: categorie.libelle, categorieId: categorie.id }));
        this.setState({
          mapDataQuestionsCategories: mapDataQuestionsCategories
        });
      });
  }

  findUser() {
    const email = sessionStorage.getItem('email');
    UsersDataService.getUserByEmail(email)
      .then(response => {
        this.props.initCurrentUser(response.data);
        this.setState({
          user: response.data
        });
      })
      .catch(e => {
        console.log(e);
      });
  }

  downloadCsv = () => {
    ExportService.getExport('.*').then((data) => {
      const element = document.createElement("a");
      const file = new Blob(['\uFEFF' + data.data], { type: 'text/csv; charset=UTF16-LE' });
      element.href = URL.createObjectURL(file);
      element.download = "ExportRéponses.csv";
      document.body.appendChild(element); // Required for this to work in FireFox
      element.click();
    });
  }

  clickAllQuestions() {
    this.props.history.push({
      pathname: '/questions',
      state: { statut: null, signalement: null }
    });
  }

  clickStatutQuestions = (statut) => {
    this.props.history.push({
      pathname: '/questions',
      state: { statut: statut, signalement: null }
    });
  }

  clickSignalementQuestions = () => {
    this.props.history.push({
      pathname: '/questions',
      state: { statut: null, signalement: true }
    });
  }

  showModalCreateQuestion = () => {
    this.setState({
      showCreateQuestion: true
    });
  }

  hideModalCreateQuestion = () => {
    this.componentDidMount();
    this.setState({
      showCreateQuestion: false
    });
  }

  getGraphicResponses() {
    return [{
      angle: this.state.pourcentageRep,
      color: '#3ABF35'
    }, {
      angle: this.state.pourcentageNonRep,
      color: '#232C4B'
    },
    {
      angle: this.state.pourcentagePerimees,
      color: '#F52547'
    }];
  }

  render() {
    const { user } = this.state;

    return (
      <div>
        { this.state.initDataDone ? (
          <>
            <CreateQuestionModalComponent
              show={this.state.showCreateQuestion}
              onHide={this.hideModalCreateQuestion}>
            </CreateQuestionModalComponent>
            <div className="row">
                <h1 className='col label-bonjour'>
                  <Translation>{(t) => <p>{t('BONJOUR')}</p>}</Translation>
                  {
                    user ? <span className="prenom-nom">{user.prenom} {user.nom}</span> : <label> </label>
                  },
            </h1>
                <button type="button" className="mr-3 my-3 btn btn-sm btn-add-qst" onClick={this.showModalCreateQuestion}>  <Translation>{(t) => <p>{t('AJOUT_QUESTION')}</p>}</Translation></button>
            </div>

            <div className="row">
            <div className='col-12'>
              <div className='row'>
                <Card className='col card-style' variant="outlined">
                  <CardContent>
                    <div className='row mx-0'>
                      <div className='col-12'>
                        <span className='card-title'>  <Translation>{(t) => <p>{t('SUIVI_QUESTION')}</p>}</Translation> </span>
                        <button type="button" onClick={() => this.clickAllQuestions()} className="btn card-btn"><Translation>{(t) => <p>{t('VOIR_TOUTES_QUESTIONS')}</p>}</Translation></button>
                      </div>
                      <div className='row mx-0'>
                        <div className='col content-style text-center hover-cursor-pointer' onClick={() => this.clickStatutQuestions(true)}>
                          <span className='number-style '>{this.state.activesNbr} </span> <br />
                          <span className='number-subtile '><Translation>{(t) => <p>{t('QUESTIONS_ACTIVES')}</p>}</Translation></span>
                        </div>
                        <div className='col content-margin content-style text-center hover-cursor-pointer' onClick={() => this.clickStatutQuestions(false)}>
                          <span className='number-style '>{this.state.inactivesNbr} </span> <br />
                          <span className='number-subtile'><Translation>{(t) => <p>{t('QUESTIONS_INACTIVES')}</p>}</Translation></span>
                        </div>
                        <div className='col content-margin content-style text-center hover-cursor-pointer' onClick={() => this.clickSignalementQuestions()} >
                          <span className='number-style '>{this.state.signalementsNbr} </span> <br />
                          <span className='number-subtile '><Translation>{(t) => <p>{t('QUESTIONS_SIGNALES')}</p>}</Translation></span>
                        </div>
                      </div>

                    </div>
                  </CardContent>
                </Card>

                <Card className='col card-style right-card-margin' variant="outlined">
                  <CardContent>
                    <span className='card-title'><Translation>{(t) => <p>{t('NOMBRE_REPONSES_QUESTIONS')}</p>}</Translation></span>
                    <div className='col content-style text-center'>
                      <span className='number-style '> {this.state.nombreResponsesQst} </span> <br />
                      <span className='number-subtile '><Translation>{(t) => <p>{t('REPONSES_RECUES')}</p>}</Translation></span>
                      <div className='number-button '><button type="button" onClick={this.downloadCsv} className="btn btn-sm btn-add-qst">  <Translation>{(t) => <p>{t('EXPORT')}</p>}</Translation></button></div>
                    </div>
                  </CardContent>
                </Card>
              </div>

              <div className="row pb-3">
                <Card className='col card-style' variant="outlined">
                  <CardContent className='row'>
                    <span className='card-title'> <Translation>{(t) => <p>{t('GRAPHIQUE_AVANCEMENT_RESPONSES')}</p>}</Translation></span>
                    <div className="row col-12">
                      <div className="pl-0 col-md-5 col-xs-12">
                        <div style={{ height: '100%', width: '100%' }} className={"mb-4"}>
                            <AutoSizer>
                              {({ height, width }) => (
                                <RadialChart
                                showLabels={true}
                                colorType="literal"
                                data={this.state.myData}
                                width={width}
                                height={height} />
                            )}
                            </AutoSizer>
                          </div>
                      </div>
                      <div className="col-md-7 py-5 my-5 d-flex flex-column size-margin-graphe">
                        <div className="col-12">
                          <div className='pourcent-style pourcent-answered'> <span className='pourcent'>{this.state.pourcentageRep}%</span> <Translation>{(t) => <p>{t('REPONDUS')}</p>}</Translation> </div>
                          <div className='pourcent-subtitle'>  <Translation>{(t) => <p>{t('DONT_PERIMEES', { pourcentage: this.state.pourcentagePerimees })}</p>}</Translation>  </div>
                        </div>
                        <div className="col-12 pourcent-margin">
                          <div className="pourcent-style pourcent-not-answered "> <span className='pourcent'>{this.state.pourcentageNonRep}%</span> <Translation>{(t) => <p>{t('NON_REPONDUS')}</p>}</Translation></div>
                        </div>
                      </div>
                    </div>
                  </CardContent>
                </Card>

                <Card className='col card-style right-card-margin' variant="outlined">
                  <CardContent className='row'>
                    <span className='card-title'> <Translation>{(t) => <p>{t('POUCENTAGE_RESPONSES_ENSEMBLE_PROJET')}</p>}</Translation></span>
                    <div className="row col-12 mx-0 pt-2">
                      <div className='pl-0 col-md-5 col-xs-12'>
                        <div style={{ height: '100%', width: '100%' }} className={"mb-4"}>
                          <AutoSizer>
                            {({ height, width }) => (
                              <RadialChart
                                showLabels={true}
                                colorType="literal"
                                data={this.state.myData1graphe}
                                width={width}
                                height={height} 
                                />
                           )}
                          </AutoSizer>
                        </div>
                      </div>

                      <div className="col-md-7 py-4 my-5 d-flex flex-column size-margin-graphe">
                        {this.state.myData1.map(data => {
                          return (<div key={data.color} className="row flex-grow-1 align-content-center pb-2"> <Translation>{(t) => <p> <FontAwesomeIcon className="mx-1" size="lg" icon={faSquare} color={data.color}></FontAwesomeIcon>  {t(data.libelle, { pourcentage: ((data.angle / (!!this.state.sumMyData1 ? this.state.sumMyData1 : 1)) * 100).toFixed(0) })}</p>}</Translation> </div>);
                        })}

                      </div>
                    </div>
                  </CardContent>
                </Card>
              </div>

              {/* <div className='row' >
                <Card className='col-6 card-style' variant="outlined">
                  <CardContent>
                    <div className='col-12'>
                      <span className='card-title'><Translation>{(t) => <p>{t('QUESTIONS_PROBLEMATIQUES')}</p>}</Translation></span>
                    </div>

                    <div className='qst-prob-bloc row'>

                      <table className="table">
                        <tbody>
                          <tr className='qst-prob-bloc-line'>
                            <td>La mesure de la satisfaction client est-elle mise en œuvre ?</td>
                            <td className='text-centre'>
                              <div className='qst-prob-btn'>
                                <i className="fa fa-circle circle-point"></i> <span className='circle-title'>  12 </span>
                              </div>
                            </td>
                          </tr>
                          <tr className='qst-prob-bloc-line'>
                            <td>A quelle date a eu lieu la dernière analyse de risque ?</td>
                            <td className='text-centre'>
                              <div className='qst-prob-btn'>
                                <i className="fa fa-circle circle-point"></i> <span className='circle-title'>  4 </span>
                              </div>
                            </td>
                          </tr>
                          <tr className='qst-prob-bloc-line'>
                            <td>Les exigences nominatives des clients à respecter sont connues?</td>
                            <td className='text-centre'>
                              <div className='qst-prob-btn'>
                                <i className="fa fa-circle circle-point"></i> <span className='circle-title'>  3 </span>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>

                    </div>

                    <div className='col-12 more-qst-bloc'>
                      <span className='col-3 offset-4 more-qst'>
                        <Translation>{(t) => <p>{t('VOIR_PLUS_DE_QUESTIONS')}</p>}</Translation>
                      </span>
                    </div>
                  </CardContent>
                </Card>
              </div> */}
            </div>
            </div>
          </>
        ) :
          <div className="d-flex justify-content-center">
            <CircularProgress />
          </div>
        }
      </div>
    );
  }
}

const AccueilDirecteur = connect(mapStateToProps, mainScreenAction)(accueilDirecteurcomponent);

export default AccueilDirecteur;
