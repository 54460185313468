import React, { Component } from 'react';
import { Button, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { Translation, withTranslation } from 'react-i18next';
import './confirmationModification.css';
import { connect } from 'react-redux';
import { mapStateToProps } from '../../selectors/MainScreenSelector';
import { mainScreenAction } from '../../actions/MainScreenActions';
import QuestionsService from "../../services/questions.service";

class ConfirmationModificationComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isSubmit: false
        };
    }

    hide = () => {
        this.props.onHide(this.state.isSubmit);
    }

    modificationQuestion = (e) => {
        let valeursQuestions = [];
        this.props.reponses.filter(reponse => reponse.isModifiable).map(reponse => valeursQuestions.push(reponse.libelle));
        QuestionsService.updateQuestion(
            {
                question: {
                    id: this.props.questionOldInfo.id,
                    libelle: this.props.libelle,
                    type: this.props.type,
                    duree_validite: (isNaN(this.props.duree_validite))? 0 : parseInt(this.props.duree_validite),
                    complement: this.props.complement,
                    id_categorie: this.props.id_categorie,
                    valeurs_question: valeursQuestions,
                    isSimpleModification: this.props.showGreenConfirmationModification,
                    id_parent_question : this.props.id_parent_question
                }
            }).then((resp) => {

                this.props.setQuestionModify(resp.data);
                this.props.showAlert({
                    type: 'success',
                    message: <Translation>{(t) => <p>{t('MODIFICATION_EFFECTUEE')}</p>}</Translation>
                });
                this.setState({
                    isSubmit: true
                })
                this.hide();
            })

        e.stopPropagation();
    }

    render() {

        return <div onClick={e => e.stopPropagation()}>
            <Modal show={this.props.show} onHide={this.hide} size="xl" backdrop="static" onClick={e => e.stopPropagation()}>
                <Modal.Header className="flex-column align-items-center custom-header">
                    <div className="row custom-justify-content">
                        <Button onClick={this.hide} className="btn-outline text-important rounded-pill" >
                            <FontAwesomeIcon icon={faChevronLeft} /> <Translation>{(t) => <p>{t('RETOUR')}</p>}</Translation>
                        </Button>
                    </div>

                    <div className="col-6 offset-3 custom-text-align-center pt-4">
                        <b>
                            <h1 className="text-important">
                                {
                                    this.props.showGreenConfirmationModification ?
                                        <Translation>{(t) => <p>{t('ACTION_CONCERVERA_REPONSES')}</p>}</Translation>
                                        :
                                        <Translation>{(t) => <p>{t('ACTION_SUPPRIMERA_TOTALITE_REPONSES')}</p>}</Translation>
                                }

                            </h1>
                        </b>
                    </div>
                </Modal.Header>

                <Modal.Footer>
                    <div className="col custom-align-center">
                        <Button className={this.props.showGreenConfirmationModification ? "custom-button custom-green-button p-10" : "custom-button custom-red-button p-10"} onClick={(e) => { this.modificationQuestion(e) }} >
                            {
                                this.props.showGreenConfirmationModification ?
                                    <Translation>{(t) => <p>{t('MISE_A_JOUR_QUESTION_ET_CONSERVATION')}</p>}</Translation>
                                    :
                                    <Translation>{(t) => <p>{t('MISE_A_JOUR_QUESTION_ET_SUPPRESSION')}</p>}</Translation>
                            }
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    }
}



const ConfirmationModification = connect(mapStateToProps, mainScreenAction)(ConfirmationModificationComponent);

export default withTranslation()(ConfirmationModification);