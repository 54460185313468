import React, { Component } from 'react';
import { mapStateToProps } from '../../selectors/MainScreenSelector';
import { mainScreenAction } from '../../actions/MainScreenActions';
import QuestionsDirecteur from './questions_directeur/questions_directeur.component';
import { connect } from 'react-redux';
import './mainScreen_directeur.css'
import CreateQuestionModalComponent from '../createquestion/createquestion.modal';
import CircularProgress from '@material-ui/core/CircularProgress';
import UtilsService from '../../services/utils.service';
import UsersService from '../../services/users.service';

import { Translation } from 'react-i18next';

class MainScreenDirecteur extends Component {

    constructor(props) {
        super(props);
        this.state = {
            backError: false,
            questions: [],
            initDataDone: false,
            categories: []
        };
    }

    componentDidMount() {
        UsersService.getUserCategoriesById(sessionStorage.getItem('user')).then(categories => {
            let questions = [];
            categories.data.forEach(categorie => {
                categorie.questions.forEach(qst => {
                    qst.categorie_libelle = categorie.libelle;
                    this.addInformationOnQuestion(qst);
                    questions.push(qst);
                })
            });
            this.props.initQuestions(questions);
            if (this.props.location.state.statut != null) {
                questions = questions.filter(qst => this.props.location.state.statut === qst.statut)
            }
            if (this.props.location.state.signalement != null) {
                questions = questions.filter(qst => qst.signalements.length > 0)
            }

            this.setState({
                categories: categories.data,
                questions: questions
            })
        }, () => {
            this.setState({
                backError: true
            });
        }).then(() => {
            this.setState({
                initDataDone: true
            })
        });
    }

    addInformationOnQuestion(question) {
        if (question.valeurs_questions && question.valeurs_questions.length) {
            question.valeurs_questions.push(question.valeurs_questions.splice(question.valeurs_questions.map(function (valeurQuestion) { return valeurQuestion.libelle }).indexOf("Autres"), 1)[0]);
        }
        this.getNbrIndicateur(question);
    }

    getNbrIndicateur(question) {
        let nbrReponses = 0;
        let nbrReponsesExpirees = 0;
        if (Array.isArray(question.reponses) && question.reponses.length) {
            let tmpReponsesNoeuds = [];
            question.reponses.forEach(reponse => {
                let currentReponseNoeud = { question: reponse.id_question, noeud: reponse.id_noeud };
                if (!tmpReponsesNoeuds.some(elem => { return elem.noeud === currentReponseNoeud.noeud && elem.question === currentReponseNoeud.question })) {
                    nbrReponses = nbrReponses + 1;
                    if (question.duree_validite !== 0 && UtilsService.calculeReponseExpired(reponse.date_fin_validite)) {
                        nbrReponsesExpirees = nbrReponsesExpirees + 1;
                    }
                }
                tmpReponsesNoeuds.push(currentReponseNoeud)
            });
        }

        question.nbr_reponses = nbrReponses;
        question.nbr_perimees = nbrReponsesExpirees;
        question.nbr_signalements = question.signalements ? question.signalements.length : 0;
    }

    backToAcceuil = () => {
        this.props.history.push("/accueil-directeur");
    }

    showModalCreateQuestion = () => {
        this.setState({
            showCreateQuestion: true
        });
    }

    hideModalCreateQuestion = () => {
        this.setState({
            showCreateQuestion: false,
            initDataDone: false
        });

        this.componentDidMount();
    }

    addNewQuestion = (newQuestion, categorieLabel) => {
        let newQuestions = this.state.questions;
        newQuestion.categorie_libelle = categorieLabel;
        this.addInformationOnQuestion(newQuestion);
        newQuestions.push(newQuestion);
        this.setState({
            questions: newQuestions
        });
    }

    render() {
        return (
            <>{this.state.initDataDone ? (
                <>{this.state.backError ? <Translation>{(t) => <p>{t('SERVICE_INDISPONIBLE')}</p>}</Translation> : 
                
                <div className='row mx-1'>
                    
                    <>
                        <CreateQuestionModalComponent
                            show={this.state.showCreateQuestion}
                            onHide={this.hideModalCreateQuestion}
                            addNewQuestion={this.addNewQuestion}>
                        </CreateQuestionModalComponent>
                    </>
                    <div className='col custom-back-button'>

                        <div className="row">
                            <div className="col-1">
                                <button type="button" onClick={this.backToAcceuil} className="btn btn-outline-dark btn-sm back-btn-radius text-nowrap back-btn ">
                                    <i className="fa fa-bold fa-angle-left left-arrow-margin"></i>
                                    <Translation>{(t) => <p>{t('RETOUR')}</p>}</Translation>
                                </button>
                            </div>
                            <div className="col-11">
                                <button type="button" className="btn btn-sm btn-add-qst" onClick={this.showModalCreateQuestion}>  <Translation>{(t) => <p>{t('AJOUT_QUESTION')}</p>}</Translation></button>
                            </div>

                        </div>
                    </div>
                    <div className='col-12'>
                        <QuestionsDirecteur categoriesMainScreen={this.state.categories} questionsMainScreen={this.state.questions} statut={this.props.location.state.statut} signalement={this.props.location.state.signalement}></QuestionsDirecteur>
                    </div>
            </div>} </>) :
                <div className="d-flex justify-content-center">
                    <CircularProgress />
                </div>
            }</>

        );
    }
}

const MainScreenDirecteurComponent = connect(mapStateToProps, mainScreenAction)(MainScreenDirecteur);

export default MainScreenDirecteurComponent;
