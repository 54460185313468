
import questions from './DataCollectorReducer'
import projets from './projetsReducer'
import categories from './categorieReducer'
import currentUser from './currentUserReducer'
import filter from './categorieFilterReducer'
import { combineReducers } from 'redux'

export default combineReducers({
    questions ,
    projets,
    currentUser,
    categories,
    filter
  })