import http from "../http-common";

class UsersDataService {

  getUserByEmail(email) {
    return http.get(`/users/${email}`);
  }


  getUser(email, password) {
    return http.get(`/user`,
      {
        params: {
          email: email,
          password: password
        }
      });
  }

  getUserCategoriesById(id) {
    return http.get('/user/'+id+'/categories');
  }

   getDirectorProjectsById(id) {
    return http.get('/user/'+id+'/projets');
  }

}

export default new UsersDataService();