import React from 'react';
import { Component } from 'react';
import { Form } from 'react-bootstrap';
import Button from '../custom_button/custom_button'
import reponseService from '../../services/reponse.service';
import projetsService from '../../services/projets.service';
import utilsService from '../../services/utils.service';
import { connect } from 'react-redux';
import { mapStateToProps } from '../../selectors/MainScreenSelector';
import { mainScreenAction } from '../../actions/MainScreenActions';
import CreatableSelect from 'react-select/creatable'
import { Translation, withTranslation } from 'react-i18next';
import ModificationQuestion from "../modificationQuestion/modificationQuestion.component";

import 'react-widgets/dist/css/react-widgets.css';
const customStyles = {
    control: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
        ...styles,
        height: '50px',
        border: '2px solid #445159',
        borderRadius: '10px',
        font: 'normal normal bold 18px/20px Open Sans',
        color :  '#232C4B',
        cursor: 'pointer',
        transition: 'none',
        outline: (isFocused ||isSelected) ? 'none' : 'default',
        outlineWidth: (isFocused ||isSelected) ? 0 : 1,
        boxShadow: (isFocused ||isSelected) ? 'none' : 'default'
        }
    },
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: '#fff',
        font: 'normal normal bold 18px/20px Open Sans',
        color :  '#232C4B',
        cursor: 'pointer'
      };
    },
  };

class PillBoxAnswer extends Component {

    constructor(props) {
        super(props);
        var options = [];
        this.props.question.valeurs_questions.forEach(valeur => {
            options.push({ label: valeur.libelle, value: valeur.code, id_valeur_question: valeur.id });
        });

        var preselectedValues = this.getPreselectedValues();
        this.state = {
            options: options,
            selectedValues: this.props.answered ? preselectedValues : [],
            edition: false,
            answersPercent: utilsService.getOccurenceAnswers(this.props.question)
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.question !== this.props.question) {
            this.setState({
                answersPercent: utilsService.getOccurenceAnswers(this.props.question)
            })
        }
    }

    getPreselectedValues = () => {
        var preselectedValues = [];
        this.props.question.valeurs_questions.forEach(valeur => {
            var option = { label: valeur.libelle, value: valeur.code, id_valeur_question: valeur.id }
            if (this.props.answered && this.props.question.reponses.map(reponse => reponse.id_valeur_question).includes(valeur.id)) {
                preselectedValues.push(option)
            }
        });
        return preselectedValues;
    }

    cancelAnswer = () => {
        this.setState({
            selectedValues: this.props.answered ? this.getPreselectedValues() : [],
            edition: false
        });
    }

    submitAnswer = () => {
        var dataToSend = []
        this.state.selectedValues.forEach(valeur => {
            dataToSend.push({
                libelle: valeur.label,
                code: valeur.value,
                id_question: this.props.question.id,
                id_valeur_question: valeur.id_valeur_question
            })
        });
        if (this.state.edition || this.props.question.is_reponse_expired) {
            reponseService.updateAnswer(this.props.question, dataToSend, null, null)
                .then(() => {
                    this.setState({
                        edition: false,
                        preselectedValues: this.getPreselectedValues(),
                    })
                    projetsService.getProjetById(this.props.idProjet).then((resp) => {
                        const questions = utilsService.extractActiveQstsWithCategories(resp.data[0].noeuds);
                        this.props.initQuestions(questions);
                        this.props.showAlert({
                            type: 'success',
                            message: <Translation>{(t) => <p>{t('REPONSE_MODIFIEE')}</p>}</Translation>
                        });
                    });

                });
        }
        else {
            reponseService.postAnswer(this.props.question, dataToSend, null, null)
                .then(() => {
                    this.cancelAnswer();
                    projetsService.getProjetById(this.props.idProjet).then((resp) => {
                        const questions = utilsService.extractActiveQstsWithCategories(resp.data[0].noeuds);
                        this.props.initQuestions(questions);
                        this.props.showAlert({
                            type: 'success',
                            message: <Translation>{(t) => <p>{t('REPONSE_ENREGISTREE')}</p>}</Translation>
                        });
                    });
                });
        }
    }

    handleChange = (selectedOptions) => {
        selectedOptions = selectedOptions ? selectedOptions : [];
        this.setState({
            selectedValues: selectedOptions
        });
    };

    editAnswer = () => {
        this.setState({ edition: true });
    }

    deleteAnswer = () => {
        reponseService.deleteAnswer(this.props.question.id, this.props.question.noeud_id).then(() => {
            this.setState({ showAlertSuccess: true, edition: false });
            projetsService.getProjetById(this.props.idProjet).then((resp) => {
                const questions = utilsService.extractActiveQstsWithCategories(resp.data[0].noeuds);
                this.props.initQuestions(questions);
                this.props.showAlert({
                    type: 'success',
                    message: <Translation>{(t) => <p>{t('REPONSE_SUPPRIMEE')}</p>}</Translation>
                });
            });
        })
    }

    deleteTagFromTagList = (tagId) => {
        console.log(tagId)
        this.setState({ 
            selectedValues: (this.state.selectedValues.filter((item) => item.id !== tagId))})
    }

    getButtons() {
        let buttons;
        if (this.props.answered && !this.state.edition) {
            buttons = <div className="col-4 text-right pr-0">
                <Button className="margin-bottom-10" action="importante" onClick={this.editAnswer} > <Translation>{(t) => <p>{t('MODIFIER')}</p>}</Translation> </Button>
                <Button className="margin-top-10" action="suppression" onClick={this.deleteAnswer} > <Translation>{(t) => <p>{t('SUPPRIMER')}</p>}</Translation> </Button>
            </div>;
        } else if (this.props.isDirection) {
            buttons = <div className="col-4 text-right pr-0">
                <ModificationQuestion question={this.props.question} idProjet={this.props.idProjet} showAlert={this.props.showAlert} setQuestionModify={this.props.setQuestionModify} />
                <Button className="margin-top-10" action="suppression" block variant="outline-danger" onClick={() => this.props.deleteAnswerDirection(this.props.question.id)} > <Translation>{(t) => <p>{t('SUPPRIMER')}</p>}</Translation> </Button>
            </div>;
        } else {
            buttons = <div className="col-4 text-right pr-0">
                <Button className="margin-bottom-10" action="importante" onClick={this.submitAnswer} disabled={!this.state.selectedValues || this.state.selectedValues.length <= 0} > <Translation>{(t) => <p>{t('VALIDER')}</p>}</Translation> </Button>
                <Button className="margin-top-10" action="simple" onClick={this.cancelAnswer} disabled={!this.state.selectedValues} > <Translation>{(t) => <p>{t('ANNULER')}</p>}</Translation> </Button>
            </div>;
        }

        return buttons;
    }

    render() {
        return (
            <>
                <Form ref={form => this.form = form} >
                    <div className="mx-0 row align-items-center">
                        <div className="col-8 pl-0 ">
                            {!this.props.isDirection ? (
                                <div>
                                    {
                                     this.state.selectedValues.map((value, i) => {
                                            return (
                                                <>
                                            <div className="custom-tag d-inline-block">
                                                <div className="float-left custom-delete-tag" onClick={() => this.setState({ selectedValues: (this.state.selectedValues.filter((item) => item.id_valeur_question !== value.id_valeur_question))})}>
                                                    <i className='fa-lg fa fa-times'></i>
                                                </div>
                                                <div className="float-left custom-libelle-tag">
                                                    <h2 className="mb-0">{value.label}</h2>
                                                </div>
                                            </div>
                                                </>
                                            )
                                        })
                                    }
                                <CreatableSelect
                                    className="custom-select-tag"
                                    options={this.state.options}
                                    isMulti
                                    placeholder={this.props.t('SAISIE_TAG')}
                                    value={this.state.selectedValues}
                                    onChange={this.handleChange}
                                    isDisabled={(this.props.answered && !this.state.edition)}
                                    styles={customStyles}
                                    formatCreateLabel={(value) => 'Ajouter le tag : ' + value}
                                />
                                </div>
                            ) : (<>
                                <div className="d-flex flex-wrap" >
                                    <div className="col-12 px-0">
                                        <div className="my-1">
                                            <div className="text-information">
                                                <p><Translation>{(t) => <b>{t('TYPE_DE_REPONSE')}</b>}</Translation><Translation>{(t) => <>{t('LISTE_TAG')}</>}</Translation> </p>
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.answersPercent.valeursQuestions.map((valeur, index) => {
                                        return (
                                            <div className="col-4 pl-0" key={index}>
                                                <div className="d-flex border-information my-1">
                                                    <div className="col-8">
                                                        {valeur.libelle}
                                                    </div>
                                                    <div className="col-4 text-right">
                                                        {this.state.answersPercent.nbAnswers === 0 ? 0 : (valeur.nbAnswer / this.state.answersPercent.nbAnswers * 100).toFixed(0)}%
                                                        </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </>
                                )
                            }
                        </div>

                        {this.getButtons()}

                    </div>
                </Form>
            </>
        )
    }
}

const PillBoxAnswerContainer = connect(mapStateToProps, mainScreenAction)(PillBoxAnswer);

export default withTranslation()(PillBoxAnswerContainer);
