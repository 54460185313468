import React, { Component } from 'react';
import { mapStateToProps } from '../../selectors/MainScreenSelector';
import { mainScreenAction } from '../../actions/MainScreenActions';
import Questions from './questions/Questions';
import { connect } from 'react-redux';
import './mainScreen.css'
import projetsService from '../../services/projets.service';
import utilsService from '../../services/utils.service';
import { Translation } from 'react-i18next';

class MainScreen extends Component {
    state = {
        idProjet: null,
        backErrror: false,
        initQuestionsFinish: false,
        showCreateQuestion: false
    };

    componentDidMount() {

        this.setState({
            idProjet: parseInt(this.props.match.params.id)
        });

        projetsService.getProjetById(this.props.match.params.id).then((resp) => {
            const questions = utilsService.extractActiveQstsWithCategories(resp.data[0].noeuds);
            this.props.initQuestions(questions);
            this.setState({
                initQuestionsFinish: true
            })
        }, () => {
            this.setState({
                backErrror: true
            });
        });
    }

    backToAcceuil = () => {
        this.props.history.push("/accueil-dp-cp");
    }

    render() {
        return (
            <div className='row px-3'>

                <div className='col-12 custom-back-button mr-3'>
                            <button type="button" onClick={this.backToAcceuil} className="position-absolute btn btn-outline-dark btn-sm text-nowrap back-btn-radius back-btn">
                                <i className="fa fa-angle-left left-arrow-margin"></i>
                                <Translation>{(t) => <p>{t('RETOUR')}</p>}</Translation>
                            </button>
                            <h1 className="text-important ml-1 text-center" >
                                {this.props.projets.length > 0 && this.props.projets.find(item => item.id === this.state.idProjet) ?
                                    this.props.projets.find(item => item.id === (this.state.idProjet)).libelle : ''}
                            </h1>
                </div>
                <div className='col-12'>
                    {this.state.idProjet ? (<Questions questionsFromMainScreen={this.props.questions} idProjet={this.state.idProjet} answered={false}></Questions>) : ""}
                    <br></br>
                    {this.state.idProjet && this.state.initQuestionsFinish ? (<Questions questionsFromMainScreen={this.props.questions} idProjet={this.state.idProjet} answered={true}></Questions>) : ""}
                </div>
                {this.state.backErrror ? <Translation>{(t) => <p>{t('SERVICE_INDISPONIBLE')}</p>}</Translation> : ''}
            </div>

        );
    }
}

const MainScreenComponent = connect(mapStateToProps, mainScreenAction)(MainScreen);

export default MainScreenComponent;
