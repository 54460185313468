import React, { Component } from 'react';
import { Button, Modal, Form } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { Translation, withTranslation } from 'react-i18next';
import './modalSignalement.css';
import { connect } from 'react-redux';
import { mapStateToProps } from '../../../../../selectors/MainScreenSelector';
import { mainScreenAction } from '../../../../../actions/MainScreenActions';
import SignalementsService from "../../../../../services/signalements.service";
import ProjetsService from "../../../../../services/projets.service";
import UtilsService from "../../../../../services/utils.service";

class ModalSignalementComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            commentaire: ''
        }
    }

    hide = () => {
        this.props.onHide();
    }

    enregistrementSignalement = (e) => {

        SignalementsService.postSignalement({
            signalement: {
                commentaire: this.state.commentaire,
                id_question: this.props.question.id,
                user_maj: sessionStorage.getItem('user'),
                id_projet: this.props.idProjet,
                id_noeud: this.props.question.noeud_id
            }
        }).then(() => {
            ProjetsService.getProjetById(this.props.idProjet).then((resp) => {
                const questions = UtilsService.extractActiveQstsWithCategories(resp.data[0].noeuds);
                this.props.initQuestions(questions);
                this.props.showAlert({
                    type: 'success',
                    message: <Translation>{(t) => <p>{t('SIGNALEMENT_ENVOYE')}</p>}</Translation>
                });
                this.hide();
            });
        })

        e.stopPropagation();
    }




    getUsersCategorie = () => {
        let rows = [];

        this.props.categories.filter(
            categorie => categorie.code === this.props.question.categorie_code
        ).map(
            categorie => categorie.users.map(
                user => rows.push(<div key={user.id} className="pl-5"><br />{" - " + user.nom + " " + user.prenom}</div>)
            )
        );

        return rows;
    }

    handleChange = (e) => {
        this.setState({
            commentaire: e.target.value
        })
    }

    render() {

        return <div onClick={e => e.stopPropagation()}>
            <Modal show={this.props.show} onHide={this.hide} size="xl" backdrop="static" onClick={e => e.stopPropagation()}>
                <Modal.Header className="flex-column align-items-center custom-header">
                    <div className="row custom-justify-content">
                        <Button onClick={this.hide} className="btn-outline text-important rounded-pill" >
                            <FontAwesomeIcon icon={faChevronLeft} /> <Translation>{(t) => <p>{t('RETOUR')}</p>}</Translation>
                        </Button>
                    </div>
                    <div className="row pt-2">
                        <div className="col-1 custom-align-left">
                            <FontAwesomeIcon size="6x" color="#FFD42A" icon={faExclamationTriangle} />
                        </div>
                        <div className="col-10 custom-align-center">
                            <b><h1 className="text-important"><Translation>{(t) => <p>{t('SIGNALER_LA_QUESTION')}</p>}</Translation></h1></b>
                            <h2 className="pt-2">{this.props.question.libelle}</h2>
                        </div>
                    </div>

                    <div className="pt-4 pl-4">
                        <h3 className="col"><Translation>{(t) => <p>{t('SIGNALER_QUESTION_DIRECTEURS_CATEGORIES')}</p>}</Translation></h3>
                        <h3 className="col">{this.getUsersCategorie()}</h3>
                    </div>
                </Modal.Header>

                <Modal.Body>
                    <div className="bg-contrast-white mx-4 py-4">
                        <div className="row">
                            <Form.Control className="col" placeholder={this.props.t('COMMENTAIRE')} value={this.state.commentaire} onChange={(e) => { this.setState({ commentaire: e.target.value }) }} />
                        </div>
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <div className="col custom-align-center">
                        <button disabled={!this.state.commentaire} className=" btn btn-success background-call-to-action ml-3" onClick={(e) => { this.enregistrementSignalement(e) }} > <Translation>{(t) => <p>{t('SIGNALER_LA_QUESTION_MAJ')}</p>}</Translation> </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    }
}



const ModalSignalement = connect(mapStateToProps, mainScreenAction)(ModalSignalementComponent);

export default withTranslation()(ModalSignalement);