import React from 'react';
import {Redirect} from 'react-router';
import http from "../http-common";
import { Auth } from "aws-amplify";

class AuthService {

    login(role) {
         sessionStorage.setItem('loggedin', 'true');
         sessionStorage.setItem('role', role);
    }


    logout() {
         sessionStorage.setItem('loggedin', 'false');
    }

    getUserRole(email, password){
        return http.get(`/user/role`,
        {
           params: {
          email: email,
          password: password
           }
        });
      }

    onEnter(roles) {
            if(roles.includes('sachant')){
                    return   <Redirect to="/accueil-dp-cp" />;
            }else if(roles.includes('directeur')){
                     return    <Redirect to="/accueil-directeur" />;
            }  
    }
 
}

export default new AuthService();