const init_state = [];

const INIT_CATEGORIES = 'INIT_CATEGORIES';

export default function categories (state = init_state, action) {

    switch (action.type) {
        case INIT_CATEGORIES:
            return action.categories;
        default:
            return state;
    }
};



