import React, { Component } from 'react';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import './menuQuestion.css';
import { Translation } from 'react-i18next';
import ModalSignalementComponent from './modalSignalement/modalSignalement.component';

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    className="custom-menu-click"
    href=""
    ref={ref}
    onClick={e => {
      e.preventDefault();
      onClick(e);
      e.stopPropagation();
    }}
  >
    <FontAwesomeIcon icon={faEllipsisV} color="#00aa9b" > </FontAwesomeIcon>
    {children}
  </a>
));

export default class MenuQuestion extends Component {

  constructor(props) {
    super(props)

    this.state = {
      showModalSignalement: false
    };
  }



  showModalSignalement = e => {
    this.setState({
      showModalSignalement: true
    });
    e.stopPropagation();
  }

  hideModalSignalement = () => {
    this.setState({
      showModalSignalement: false
    });
  }

  render() {
    return (
      <div>
        <ModalSignalementComponent
          show={this.state.showModalSignalement}
          onHide={this.hideModalSignalement}
          question={this.props.question}
          idProjet={this.props.idProjet}
          showAlert={this.props.showAlert}>
        </ModalSignalementComponent>
        <Dropdown>
          <Dropdown.Toggle as={CustomToggle} id="dropdown-basic">

          </Dropdown.Toggle>

          <Dropdown.Menu onClick={e => {e.stopPropagation()}}>
            <Dropdown.Item disabled={this.props.question.signalements.filter(signalement => signalement.user_maj.toString() === sessionStorage.getItem("user") && signalement.id_projet.toString() === this.props.idProjet).length} onClick={this.showModalSignalement}>
              <strong><Translation>{(t) => <p>{t('SIGNALER')}</p>}</Translation></strong>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    )
  }
}