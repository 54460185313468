import React from "react";
import { Button, Modal, FormControl, ToggleButton, ToggleButtonGroup, Form, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faCircle, faCheckCircle, faPlus, faTrash, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import QuestionsService from "../../services/questions.service";
import UsersService from "../../services/users.service";
import { Translation, withTranslation } from 'react-i18next';
import "./createquestion.css";
import ConfirmationModaleModification from '../modificationQuestion/confirmationModification.component';
import ReactDOMServer from 'react-dom/server';
import Divider from '@material-ui/core/Divider';
import zIndex from "@material-ui/core/styles/zIndex";

const customStylesParent = {
    singleValue: styles => ({
        ...styles,
        width: "100%"
    }),
    menu: styles => ({
        ...styles,
        margin: "0px",
        zIndex: "20"
    }),
};

const customStylesType = {
    menu: styles => ({
        ...styles,
        zIndex: "20",
        margin: "0px"
    }),
};

const typeQuestions = [
    {
        value: "RADIO_BOUTON",
        label: <Translation>{(t) => <p>{t('RADIO_BOUTON')}</p>}</Translation>,
        isMulti: true,
        isAutrePossible: true
    },
    {
        value: "LISTE_TAG",
        label: <Translation>{(t) => <p>{t('LISTE_TAG')}</p>}</Translation>,
        isMulti: true,
        isAutrePossible: true
    },
    {
        value: "LISTE_DEROULANTE",
        label: <Translation>{(t) => <p>{t('LISTE_DEROULANTE')}</p>}</Translation>,
        isMulti: true,
        isAutrePossible: true
    },
    {
        value: "SAISIE_TEXT",
        label: <Translation>{(t) => <p>{t('SAISIE_TEXTE')}</p>}</Translation>,
        isMulti: false,
        isAutrePossible: false
    },
    {
        value: "SAISIE_NUMERIQUE",
        label: <Translation>{(t) => <p>{t('SAISIE_NUMERIQUE')}</p>}</Translation>,
        isAutrePossible: true
    },
    {
        value: "DATE",
        label: <Translation>{(t) => <p>{t('DATE')}</p>}</Translation>,
        isMulti: false,
        isAutrePossible: true
    },
]

const radiosConstant = ['/', 24, 12, 6];

const INITIAL_STATE = {
    radiosValidity: radiosConstant,
    selectedValidity: null,
    selectedCategorie: '',
    typedValidity: 1,
    isQuestionSubmitted: false,
    selectedType: '',
    questionName: '',
    questionDescription: '',
    errorMessages: [],
    reponses: [],
    addAnswers: false,
    categories: [],
    isModification: false,
    showModalConfirmationModification: false,
    showGreenConfirmationModification: 0,
    parentQuestionId: null,
    optionsParent: []
}

class CreateQuestionModalComponent extends React.Component {
    constructor(props) {
        super(props);
        this.setTypedValidity = this.setTypedValidity.bind(this);
    }

    componentWillMount() {
        if (this.props.question) {
            this.initStateModification();
        }
        else {
            this.setState({ ...INITIAL_STATE, reponses: [] });
        }
        this.setCategories();
    }

    getValeursQuestionsForModification = () => {
        let valeursQuestions = [];
        if (this.props.question.valeurs_questions && this.props.question.valeurs_questions.length) {
            this.props.question.valeurs_questions.map(valeurQuestion => valeursQuestions.push({ libelle: valeurQuestion.libelle, isModifiable: false }));
        }

        return valeursQuestions;
    }

    setCategories = () => {
        UsersService.getUserCategoriesById(sessionStorage.getItem('user')).then(response => {
            let selectedCategorie;
            if (response.data && response.data.length === 1) {
                selectedCategorie = {
                    value: response.data[0].id,
                    label: response.data[0].libelle
                }
            } else if (this.props.question) {
                let categorie = response.data.find(categorie => { return categorie.id === this.props.question.id_categorie });
                selectedCategorie = {
                    value: categorie.id,
                    label: categorie.libelle
                }
            } else {
                selectedCategorie = '';
            }
            this.setState({
                categories: response.data,
                selectedCategorie: selectedCategorie
            })
            this.setState({
                optionsParent: this.updateOptionsParentQuestion(selectedCategorie)
            })
        })
    }

    setRadioValue = (value) => {
        this.setState({
            selectedValidity: value
        })
    }

    initStateModification() {
        let question = this.props.question;
        console.log(question.duree_validite)
        this.state = {
            radiosValidity: radiosConstant,
            selectedValidity: question.duree_validite === 0 ? '/' : question.duree_validite,
            selectedCategorie: '',
            typedValidity: radiosConstant.find(value => value === question.duree_validite) || question.duree_validite === 0 ? 1 : question.duree_validite,
            isQuestionSubmitted: false,
            selectedType: typeQuestions.find(typeQuestion => typeQuestion.value === question.type),
            questionName: question.libelle,
            questionDescription: question.complement,
            errorMessages: [],
            reponses: this.getValeursQuestionsForModification(),
            addAnswers: typeQuestions.find(typeQuestion => typeQuestion.value === question.type).isMulti,
            categories: [],
            optionsParent: [],
            isModification: true,
            showModalConfirmationModification: false,
            showGreenConfirmationModification: 0,// 1 pour afficher la confirmation de modification verte, 0 si rouge
            parentQuestionId: question.id_parent_question
        };


    }

    setTypedValidity(e) {

        // const regex = /^[0-9\b]+$/;
        const regex = /^[-+]?\d*$/; // Acceptation nombre négatif, temporaire ...
        if (e.target.value === '' || regex.test(e.target.value)) {
            this.setState({
                typedValidity: e.target.value,
                selectedValidity: e.target.value
            });
        }
    }

    hide = () => {
        this.newQuestion();
        this.props.onHide();
    }

    hideModalConfirmationModification = (isSubmit) => {
        this.setState({
            showModalConfirmationModification: false
        });
        if (isSubmit) {
            if (this.props.isFromModalSignalement) {
                this.props.onHide(isSubmit);
            }
            this.hide();
        }
    }

    render() {
        return (
            <>
                {this.state.showModalConfirmationModification ? <ConfirmationModaleModification
                    show={this.state.showModalConfirmationModification}
                    onHide={this.hideModalConfirmationModification}
                    idProjet={this.props.idProjet}
                    showAlert={this.props.showAlert}
                    setQuestionModify={this.props.setQuestionModify}
                    questionOldInfo={this.props.question}
                    libelle={this.state.questionName}
                    type={this.state.selectedType.value}
                    duree_validite={this.state.selectedValidity}
                    complement={this.state.questionDescription}
                    id_categorie={this.state.selectedCategorie.value}
                    id_parent_question={this.state.parentQuestionId}
                    reponses={this.state.reponses}
                    showGreenConfirmationModification={this.state.showGreenConfirmationModification}>
                </ConfirmationModaleModification> : ""}

                <Modal show={this.props.show && !this.state.showModalConfirmationModification} onHide={this.hide} size="xl" backdrop="static">
                    <Modal.Header className="flex-column align-items-center">
                        <div className="row">
                            <Button onClick={this.hide} className="btn-outline text-important rounded-pill" >
                                <FontAwesomeIcon icon={faChevronLeft} /> <Translation>{(t) => <p>{t('RETOUR')}</p>}</Translation>
                            </Button>
                        </div>
                        <div className="row pt-2">
                            <b>
                                <h1 className="text-important">
                                    {this.state.isModification ?
                                        <Translation>{(t) => <p>{t('MODIFICATION_QUESTION_TITLE')}</p>}</Translation>
                                        : <Translation>{(t) => <p>{t('AJOUT_QUESTION_TITLE')}</p>}</Translation>
                                    }
                                </h1>
                            </b>
                        </div>
                    </Modal.Header>

                    <Modal.Body>
                        <div className="bg-contrast-white mx-4 py-4">
                            {this.state.isQuestionSubmitted ? this.modalBodySubmitted() : this.modalBodyNotSubmitted()}
                            {this.state.isModification ? <div className="row pt-3">
                                <div className="col-6">
                                    <div className="custom-background-button-modification">
                                        <div className="pb-3">
                                            <Translation>{(t) => <p>{t('CONSERVER_REPONSES_LIEES')}</p>}</Translation>
                                        </div>

                                        <Button onClick={() => this.publishQuestion(1)} className="custom-button-modification border-green pb-2 pt-2">
                                            <Translation>{(t) => <p>{t('MODIFICATION_MINEURE')}</p>}</Translation>
                                        </Button>
                                    </div>
                                </div>

                                <div className="col-6">
                                    <div className="custom-background-button-modification">
                                        <div className="pb-3">
                                            <Translation>{(t) => <p>{t('SUPPRIMER_REPONSES_LIEES')}</p>}</Translation>
                                        </div>

                                        <Button onClick={() => this.publishQuestion(0)} className="custom-button-modification border-red pb-2 pt-2">
                                            <Translation>{(t) => <p>{t('MODIFICATION_MAJEURE')}</p>}</Translation>
                                        </Button>
                                    </div>
                                </div>
                            </div> : ""}
                        </div>
                    </Modal.Body>

                    <Modal.Footer className="justify-content-center">
                        {this.state.isModification ? "" : this.state.isQuestionSubmitted ? <Button className="w-25 btn-outline outline-call-to-action" onClick={this.newQuestion}> <FontAwesomeIcon icon={faPlus} size="lg" ></FontAwesomeIcon></Button> :
                            <div className="justify-content-center">
                                <button className="btn btn-outline-dark" onClick={() => this.publishQuestion(0)} > <Translation>{(t) => <p>{t('INACTIVER_QUESTION')}</p>}</Translation> </button>
                                <button className="btn btn-success background-call-to-action ml-3" onClick={() => this.publishQuestion(1)} > <Translation>{(t) => <p>{t('ACTIVER_QUESTION')}</p>}</Translation> </button>
                            </div>}
                    </Modal.Footer>
                </Modal>
            </>
        )
    }

    newQuestion = () => {
        if (!this.props.question) {
            this.setState(INITIAL_STATE);
            this.setState({ reponses: [] });
        } else {
            this.initStateModification();
        }
        this.setCategories();
    }


    publishQuestion = (state) => {
        var list = []
        if (!this.state.questionName) {
            list.push(<Translation>{(t) => <p>{t('ERROR_CREATION_QUESTION_NOM_MISSING')}</p>}</Translation>)
        }
        if (!this.state.selectedType.value) {
            list.push(<Translation>{(t) => <p>{t('ERROR_CREATION_QUESTION_TYPE_MISSING')}</p>}</Translation>)
        }
        if (!this.state.selectedCategorie.value) {
            list.push(<Translation>{(t) => <p>{t('ERROR_CREATION_QUESTION_CATEGORIE_MISSING')}</p>}</Translation>)
        }
        if (this.state.selectedType.isMulti) {
            if (!this.state.reponses.length) {
                list.push(<Translation>{(t) => <p>{t('ERROR_CREATION_QUESTION_REPONSES')}</p>}</Translation>)
            } else if (this.state.reponses.filter(reponse => reponse.libelle).length !== this.state.reponses.length) {
                list.push(<Translation>{(t) => <p>{t('ERROR_CREATION_QUESTION_REPONSES_MISSING')}</p>}</Translation>)
            }
        }

        if (!this.state.typedValidity || !this.state.selectedValidity) {
            list.push(<Translation>{(t) => <p>{t('ERROR_CREATION_QUESTION_VALIDITY_MISSING')}</p>}</Translation>)
        }

        this.setState({ errorMessages: list });

        if (list.length <= 0) {
            if (this.state.isModification) {
                this.setState({
                    showModalConfirmationModification: true,
                    showGreenConfirmationModification: state
                })
            } else {

                let valeursQuestions = [];
                if (this.state.reponses && this.state.reponses.length) {
                    this.state.reponses.map(reponse => valeursQuestions.push(reponse.libelle));
                }
                QuestionsService.postQuestion({
                    question: {
                        libelle: this.state.questionName,
                        type: this.state.selectedType.value,
                        duree_validite: this.state.selectedValidity === '/' ? 0 : this.state.selectedValidity,
                        complement: this.state.questionDescription,
                        id_categorie: this.state.selectedCategorie.value,
                        statut: state,
                        id_parent_question: this.state.parentQuestionId
                    },
                    valeurs_question: valeursQuestions
                }).then((data) => {
                    if (this.props.addNewQuestion) {
                        this.props.addNewQuestion(data.data, this.state.selectedCategorie.label);
                    }
                    this.setState(INITIAL_STATE)
                    this.setState({
                        isQuestionSubmitted: true,
                        isQuestionActivated: state
                    })
                })
            }
        }
    }

    handleChangeType = (valueSelected) => {
        this.setState({
            selectedType: valueSelected,
            addAnswers: valueSelected.isMulti,
            reponses: []
        })
    }

    getChildQuestion = (questionParent) => {
        let listChildrenQuestion = []
        let questions = this.state.categories.flatMap(categorie => categorie.questions)
        listChildrenQuestion = questions.filter(question => question.id_parent_question === questionParent.id)
        listChildrenQuestion.forEach(childQuestion => {
            this.getChildQuestion(childQuestion).forEach(childChildQuestion => listChildrenQuestion.push(childChildQuestion))
        })
        return listChildrenQuestion;
    }

    updateOptionsParentQuestion = (selectedCategorie) => {

        let questionToReturn = [];
        if (selectedCategorie && this.state.categories && this.state.categories.length > 0) {
            questionToReturn = this.state.categories.find((categorie) => categorie.id === selectedCategorie.value).questions
                .filter(question => this.props.question ? question.id !== this.props.question.id : true)
                .filter(question => this.props.question ? !this.getChildQuestion(this.props.question).includes(question) : true)
                .map((question) => {
                    return {
                        value: question.id,
                        label: question.libelle,
                        id_categorie: question.id_categorie,
                        statut: question.statut
                    }
                });
        }
        return questionToReturn;
    }

    handleChangeCategorie = (valueSelected) => {
        this.setState({
            selectedCategorie: valueSelected,
            optionsParent: this.updateOptionsParentQuestion(valueSelected)
        })
    }

    modalBodySubmitted = () => {
        return (
            <div className=" d-flex flex-column align-items-center">
                <b><h3 className="text-information">{
                    this.state.isQuestionActivated ? <Translation>{(t) => <p>{t('QUESTION_ACTIVEE_AVEC_SUCCES')}</p>}</Translation> :
                        <Translation>{(t) => <p>{t('QUESTION_ENREGISTREE_AVEC_SUCCES')}</p>}</Translation>
                }</h3></b>
                <FontAwesomeIcon icon={faCheckCircle} size="4x" color="green" ></FontAwesomeIcon>
            </div>
        );
    }

    removeAnswer = (value) => {
        const newList = this.state.reponses.filter((item) => item.libelle !== value);
        this.setState({
            reponses: newList
        });
    }

    pushAnswer = (value) => {
        var newListAnswer = this.state.reponses;
        newListAnswer.push({ libelle: value, isModifiable: true })
        newListAnswer.push(newListAnswer.splice(newListAnswer.map(function (answer) { return answer.libelle }).indexOf("Autres"), 1)[0]);
        this.setState({
            reponses: newListAnswer
        });
    }

    handleChangeReponses = (value, idx) => {
        var newListAnswer = this.state.reponses;
        newListAnswer[idx] = { libelle: value, isModifiable: true };
        newListAnswer.push(newListAnswer.splice(newListAnswer.map(function (answer) { return answer.libelle }).indexOf("Autres"), 1)[0]);
        this.setState({
            reponses: newListAnswer
        });
    }

    //ref={input => input && input.focus()}

    displayRowAnswer = (reponse, idx) => { 
        return (
            <InputGroup>
                <FormControl autoFocus={this.state.reponses && this.state.reponses.length -1 === idx ? true:false} placeholder={this.props.t('REPONSE')} value={reponse.libelle} onChange={(e) => this.handleChangeReponses(e.target.value, idx)} disabled={reponse.libelle === "Autres" || !reponse.isModifiable} />
                {
                    !reponse.isModifiable ? "" :
                        <InputGroup.Append>
                            <Button id={idx} key={idx} onClick={(idx) => { this.removeAnswer(reponse.libelle) }} className="background-call-to-action" ><FontAwesomeIcon icon={faTrash} ></FontAwesomeIcon></Button>
                        </InputGroup.Append>
                }
            </InputGroup>

        );
    }

    getOptionHtml = (data, context) => {
        if (context === "value") {
            return ReactDOMServer.renderToStaticMarkup(<div>Question parent : {data.label}</div>);
        } else if (context === "menu") {
            return ReactDOMServer.renderToStaticMarkup(<div className="row">
                <div className="col-1">
                    {data.statut ? (<FontAwesomeIcon icon={faEye} color="#3ABF35" ></FontAwesomeIcon>) : (<FontAwesomeIcon icon={faEyeSlash} color="#BF2222"></FontAwesomeIcon>)}
                </div>
                <div className="col-8">
                    {data.label.length > 82 ? data.label.substring(0, 82) + "..." : data.label}
                </div>
                <div className="col-3 text-right">
                    {this.getHtmlCategorie(data.id_categorie)}
                </div>
            </div>);
        }

    }

    getHtmlCategorie = (id_categorie) => {
        var categorieColor = null;
        var categorieLibelle = null;
        if (this.state.categories.length > 0) {
            this.state.categories.forEach(categorie => {
                if (id_categorie === categorie.id) {
                    categorieColor = categorie.color;
                    categorieLibelle = categorie.libelle;
                }
            });
        }
        return categorieColor && categorieLibelle ? (<p className="pull-right"><FontAwesomeIcon icon={faCircle} color={categorieColor} > </FontAwesomeIcon> {categorieLibelle}</p>) : '';
    }

    modalBodyNotSubmitted = () => {
        return (
            <>
                <div className="text-center text-danger pb-3">
                    {this.state.errorMessages.map(message => (<li key={message}>{message}</li>))}
                </div>
                <div className="row align-items-center">
                    <div className="col-3">
                        <Select
                            options={this.state.categories.map((categorie) => ({
                                value: categorie.id,
                                label: categorie.libelle
                            }))}
                            value={this.state.selectedCategorie}
                            onChange={this.handleChangeCategorie}
                            className="btn-outline text-important rounded"
                            placeholder={this.props.t('CATEGORIE')}
                        />
                    </div>
                    <div className="col-2 text-right">
                        <h3 className="text-information my-0 align-middle"><Translation>{(t) => <p>{t('VALIDITE')}</p>}</Translation></h3>
                    </div>
                    <div className="col-7">
                        <ToggleButtonGroup value={this.state.selectedValidity} onChange={this.setRadioValue} name="radio" type="radio" toggle>
                            {this.state.radiosValidity.map((radio, idx) => (
                                <ToggleButton
                                    key={idx}
                                    type="button"
                                    value={radio}
                                    className="mx-1  h-100 text-nowrap rounded btn-outline text-important"
                                >
                                    {radio !== "/" ? radio + " " + this.props.t('MOIS') : " " + radio + " "}
                                </ToggleButton>
                            ))}
                            <ToggleButton
                                key={'saisie'}
                                value={this.state.typedValidity}
                                className="mx-1 py-0 h-100 text-nowrap rounded btn-outline text-important"
                            >
                                <InputGroup >
                                    <Form.Control type="text" maxLength="3" className="border-0 numeric-input-3 text-important" placeholder="1" value={this.state.typedValidity} onClick={this.setTypedValidity} onChange={this.setTypedValidity} />
                                    <InputGroup.Append  >
                                        <InputGroup.Text className="border-0 bg-transparent text-important btn" ><Translation>{(t) => <p>{t('MOIS')}</p>}</Translation></InputGroup.Text>
                                    </InputGroup.Append>
                                </InputGroup>
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </div>
                </div>

                <div className="row my-2 align-items-center">
                    <div className="col-8">
                        <Form.Control placeholder={this.props.t('NOM_QUESTION')} value={this.state.questionName} onChange={(e) => { this.setState({ questionName: e.target.value }) }} />
                    </div>
                    <div className="col-4">
                        <Select
                            options={typeQuestions}
                            value={this.state.selectedType}
                            onChange={this.handleChangeType}
                            className="btn-outline text-important rounded"
                            placeholder={this.props.t('TYPE_REPONSE')}
                            isDisabled={this.state.isModification}
                            styles={customStylesType}
                        />
                    </div>
                </div>
                <div className="row my-2 align-items-center">
                    <div className="col">
                        <Form.Control as="textarea" placeholder={this.props.t('DESCRIPTION_OPTIONNELLE')} value={this.state.questionDescription} onChange={(e) => { this.setState({ questionDescription: e.target.value }) }} />
                    </div>
                </div>
                <div className="row my-2 align-items-center">
                    <div className="col">
                        <Select
                            styles={customStylesParent}

                            placeholder="Rechercher une question parent à lier (optionnel)"
                            options={this.updateOptionsParentQuestion(this.state.selectedCategorie)}
                            value={this.state.optionsParent.find(option => option.value === this.state.parentQuestionId)}
                            formatOptionLabel={(data, { context }) => {
                                return (<span className="w-100" dangerouslySetInnerHTML={{ __html: this.getOptionHtml(data, context) }} />
                                )
                            }}
                            onChange={(data) => { this.setState({ parentQuestionId: data ? data.value : null }) }}
                            isClearable={true}
                        ></Select>
                    </div>
                </div>


                {this.state.addAnswers ? (
                    <>
                        <Divider variant="middle" />
                        {this.state.reponses.map(
                            (reponse, idx) => (
                                <div key={idx} className="row my-2 justify-content-center">
                                    <div className="col-10">
                                        {this.displayRowAnswer(reponse, idx)}
                                    </div>
                                </div>
                            ))}

                        <div className="row my-2 justify-content-center">
                            <div className="col-5">
                                <Button className="background-call-to-action" onClick={() => { this.pushAnswer("") }}><FontAwesomeIcon icon={faPlus} /> <Translation>{(t) => <p>{t('AJOUTER_UNE_REPONSE')}</p>}</Translation></Button>
                            </div>
                            <div className="col-5 text-right">
                                {!this.state.reponses.some(reponse => reponse.libelle === "Autres") ? (

                                    <Button className="background-call-to-action" onClick={() => { this.pushAnswer("Autres") }}><FontAwesomeIcon icon={faPlus} /> <Translation>{(t) => <p>{t('AJOUTER_CHOIX_AUTRE')}</p>}</Translation></Button>
                                ) : ''}
                            </div>
                        </div>

                    </>

                ) : ''}
            </>
        );
    }
}

export default withTranslation()(CreateQuestionModalComponent);