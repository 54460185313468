import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./accueil.css";
import UsersDataService from "../../services/users.service";
import ProjetsDataService from "../../services/projets.service";
import { XYPlot, VerticalBarSeries, RadialChart } from 'react-vis';
import { AutoSizer } from "react-virtualized";
import ProjetItem from "./itemProjet/projet-item.component";
import { connect } from 'react-redux';
import { mapStateToProps } from '../../selectors/MainScreenSelector';
import { mainScreenAction } from "../../actions/MainScreenActions";
import CategoriesService from '../../services/categories.service';
import { Translation } from 'react-i18next';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import UtilsService from '../../services/utils.service'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquare } from '@fortawesome/free-solid-svg-icons'
import CircularProgress from '@material-ui/core/CircularProgress';


class AccueilComponent extends Component {

  constructor(props) {
    super(props);
    this.findUser = this.findUser.bind(this);
    this.findProjetsByUser = this.findProjetsByUser.bind(this);
    this.initData = this.initData.bind(this);
    this.findCategories = this.findCategories.bind(this);

    this.state = {
      user: null,
      currentIndex: -1,
      projets: [],
      categories: [],
      dataCategorie: [],
      dataRadialChart: [],
      nbrTotalQuestion: 0,
      nbrGlobalQuestionRepondu: 0,
      nbrGlobalQuestionNonRepondu: 0,
      showCreateQuestion: false,
      nbrQuestionExpire: 0,
      initDataDone: false
    };
  }

  componentDidMount() {
    this.findUser();
    this.findCategories();
  }

  findCategories = () => {
    CategoriesService.getCategories().then(
      response => {
        this.props.initCategories(response.data);
      })
  }

  findUser() {
    UsersDataService.getUserByEmail(sessionStorage.getItem('email'))
      .then(response => {
        this.props.initCurrentUser(response.data);
        this.setState({
          user: response.data
        });
        this.findProjetsByUser();
      })
      .catch(e => {
        console.log(e);
      });
  }

  findProjetsByUser() {

    ProjetsDataService.getProjetsByIdUser(sessionStorage.getItem('user')).then((response) => {
      this.props.initProjets(response.data);
      this.setState({
        projets: response.data
      });
      const questions = UtilsService.extractActiveQstsWithCategories(response.data[0].noeuds);
      this.props.initQuestions(questions);
      this.initData(response.data);
    }, () => {
      this.setState({
        backErrror: true
      });
    }).then(() => {
      this.setState({
        initDataDone: true
      })
    });
  }

  initData(projets) {
    let nbrTotalQuestion = 0;
    let nbrGlobalQuestionRepondu = 0;
    let nbrGlobalQuestionNonRepondu = 0;
    let nbrQuestionExpire = 0;
    let dataCategorie = [];
    let dataCategorieTemp = [];
    let dataRadialChart = [];
    let isEmptyCategories = true;

    projets.forEach((projet, indexProjet) => {
      projet.noeuds.forEach(noeud => {
        if(noeud.categories && noeud.categories.length > 0){
          isEmptyCategories = false;
          }
        noeud.categories.forEach((categorie) => {
          categorie.nbQuestionsAnswered = 0;
          categorie.nbTotalQuestions = 0;
          if (categorie.questions) {
            categorie.questions.forEach(question => {
              if (question.statut) {
                nbrTotalQuestion++;

                categorie.nbTotalQuestions++;

                if (!Array.isArray(question.reponses) || !question.reponses.length || question.reponses.filter(reponse => reponse.id_noeud === noeud.id).length === 0) {
                  nbrGlobalQuestionNonRepondu = nbrGlobalQuestionNonRepondu + 1;
                }
                else {
                  if (question.is_reponse_expired) {
                    nbrQuestionExpire++;
                  }
                  nbrGlobalQuestionRepondu++;
                  categorie.nbQuestionsAnswered++;

                }
              }
            })
          }
          let index = dataCategorieTemp.findIndex(categorieTmp => categorieTmp.id === categorie.id)

          if (index < 0) {
            dataCategorieTemp.push({ id: categorie.id, label: categorie.libelle, nbQuestionsAnswered: categorie.nbQuestionsAnswered, nbTotalQuestions: categorie.nbTotalQuestions, color: categorie.color });
          } else {
            dataCategorieTemp[index].nbQuestionsAnswered += categorie.nbQuestionsAnswered;
            dataCategorieTemp[index].nbTotalQuestions += categorie.nbTotalQuestions;
          }
        })
      })
    });
     if(isEmptyCategories){
          this.props.categories.forEach((category , indexCateg) =>{
              dataCategorie.push({ label: category.libelle, x: indexCateg, y: 0, color: category.color, opacity: 1 });
              dataCategorie.push({ x: indexCateg, y: 1, color: category.color, opacity: 0.2 });

              dataRadialChart.push({ angle: 0, color: category.color, libelle: category.libelle })
          });
        }

    dataRadialChart.push({ angle: nbrGlobalQuestionRepondu, color: "#3ABF35", libelle: "Questions répondues", opacity: 1 })

    dataCategorieTemp.forEach((categorie, index) => {
      dataCategorie.push({ label: categorie.label, x: index, y: categorie.nbQuestionsAnswered / (!!categorie.nbTotalQuestions ? categorie.nbTotalQuestions : 1), color: categorie.color, opacity: 1 });
      dataCategorie.push({ x: index, y: 1, color: categorie.color, opacity: 0.2 });

      dataRadialChart.push({ angle: categorie.nbTotalQuestions - categorie.nbQuestionsAnswered, color: categorie.color, libelle: categorie.label })
    })

    this.setState({
      nbrTotalQuestion: nbrTotalQuestion,
      nbrGlobalQuestionRepondu: nbrGlobalQuestionRepondu,
      nbrGlobalQuestionNonRepondu: nbrGlobalQuestionNonRepondu,
      nbrQuestionExpire: nbrQuestionExpire,
      dataCategorie: dataCategorie,
      dataRadialChart: dataRadialChart
    });
  }

  clickOnProjet(projet) {
    this.props.history.push("/projet/" + projet.id);
  }

  render() {
    const { user, projets, currentIndex } = this.state;

    return (

      <div>
        { this.state.initDataDone ? (
          <div>
            <div className="row no-padding">
                <h1 className="col label-bonjour">
                  <Translation>{(t) => <span>{t('BONJOUR')}</span>}</Translation>
                  {
                    user ? <span className="prenom-nom">{user.prenom} {user.nom}</span> : <label> </label>
                  },
              </h1>
            </div>

            <div className="row">
              <div className="col my-1">
                <Card className='card-style' variant="outlined">
                  <CardContent>
                    <div className="label-title-item">
                      <h2 className="text-information label-title-item">
                        <Translation>{(t) => <p>{t('LISTE_DE_MES_PROJETS')}</p>}</Translation>
                      </h2>
                    </div>

                    <ul className="list-group">
                      {projets &&
                        projets.map((projet, index) => (
                          <li
                            className={
                              "item-projet list-group-item manage-cursor" +
                              (index === currentIndex ? "active" : "")
                            }
                            onClick={() => this.clickOnProjet(projet)}
                            key={index}
                          >
                            <ProjetItem projet={projet} />
                          </li>
                        ))}
                    </ul>
                  </CardContent>

                </Card>
                <Card className='card-style'>
                  <CardContent>
                    <h2 className="text-information label-title-item">
                      <Translation>{(t) => <p>{t('CATEGORIES_QUESTION_REPONDUES')}</p>}</Translation>
                    </h2>
                    <div className="row" style={{ height: 250 }} >
                      <AutoSizer>
                        {({ height, width }) => (
                          <XYPlot
                            width={width}
                            height={height} >
                            <VerticalBarSeries
                              colorType='literal'
                              data={this.state.dataCategorie}
                            />
                          </XYPlot>
                        )}</AutoSizer>
                    </div>
                    <div className="row mx-4 d-flex  flex-wrap">
                      {this.state.dataCategorie.map((categorie, index) => {
                        return categorie.opacity === 1 ? (<div key={index} className="col-4 py-1 px-0"> <FontAwesomeIcon icon={faSquare} color={categorie.color}></FontAwesomeIcon> {categorie.label} {(categorie.y * 100).toFixed(0)}% </div>) : '';
                      })}
                    </div>
                  </CardContent>
                </Card>
              </div>
              <div className='col m-1'>
                <Card className='card-style' variant="outlined">
                  <CardContent>
                    <h2 className="text-information label-title-item">
                      <Translation>{(t) => <p>{t('REPONSES_NECESSITANT_MAJ')}</p>}</Translation>
                    </h2>
                    <div className='col content-style text-center'>
                      <span className='number-style '> {this.state.nbrQuestionExpire} </span> <br />
                      <span className='number-subtile '><Translation>{(t) => <p>{t('REPONSES_EXPIRE')}</p>}</Translation></span>

                    </div>
                  </CardContent>
                </Card>
                <Card className='card-style' variant="outlined">
                  <CardContent>
                    <h2 className="text-information label-title-item">
                      <Translation>{(t) => <p>{t('QUESTIONS_REPONDUES_ET_EN_ATTENTE')}</p>}</Translation>
                    </h2>
                    <div className="row p-0 m-0">
                      <div className="col-12 col-md-4 pl-0 m-0 radial-chart-js" >
                        <AutoSizer>
                          {({ height, width }) => (
                            <RadialChart
                              showLabels={true}
                              colorType="literal"
                              data={this.state.dataRadialChart}
                              width={width}
                              height={height} />)}
                        </AutoSizer>
                      </div>
                      <div className="col-12 col-md-8 m-0 d-flex flex-wrap legende-chart-js">
                        {this.state.dataRadialChart.map(categorie => {
                          return categorie.libelle === "Questions répondues" ?
                            (<div key={categorie.color.toString()} className="col-12 p-0"> <FontAwesomeIcon icon={faSquare} color={categorie.color}></FontAwesomeIcon> {categorie.libelle} {((categorie.angle / (!!this.state.nbrTotalQuestion? this.state.nbrTotalQuestion : 1)) * 100).toFixed(0)}% </div>)
                            : (<div key={categorie.color.toString()} className={"p-0 " + (this.state.dataRadialChart.findIndex(x => x.libelle === categorie.libelle) % 2 === 0 ? 'col-12 col-lg-5' : 'col-12 col-lg-7')}> <FontAwesomeIcon icon={faSquare} color={categorie.color}></FontAwesomeIcon> {categorie.libelle} {((categorie.angle / (!!this.state.nbrTotalQuestion? this.state.nbrTotalQuestion : 1)) * 100).toFixed(0)}% </div>)
                        })}
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </div>
            </div>
          </div>
        ) :
          <div className="d-flex justify-content-center">
            <CircularProgress />
          </div>
        }

      </div>

    );
  }
}

const Accueil = connect(mapStateToProps, mainScreenAction)(AccueilComponent);

export default Accueil;
