import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps } from '../../selectors/MainScreenSelector';
import { mainScreenAction } from '../../actions/MainScreenActions';
import { Translation } from 'react-i18next';


class CategorieFilterComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            filter: '',
            handleFilter: null,
            categories: this.props.categories
        }
    }

    render() {
        this.state.filter = this.props.componentName === 'questionsNonRepondues' ? this.props.filter.qstNonRep_filter : this.props.filter.qstRep_filter;
        this.state.handleFilter = this.props.componentName === 'questionsNonRepondues' ? this.props.handleFilterNonRep : this.props.handleFilterRep;

        return this.state.categories && this.state.categories.length > 0 ?
            (
                <div className='white-background'>
                    <div className=' filter-container'>
                        <div className={this.state.filter === 'ALL' ? 'selected-filter btn text-nowrap background-call-to-action ' : 'filter-text btn text-nowrap'}
                            onClick={() => this.state.handleFilter('ALL')}>
                            <Translation>{(t) => <p>{t('TOUTES')}</p>}</Translation></div>
                        {
                            this.state.categories.map((categorie, index) => {
                                return (
                                    <div key={'filter' + index} className={
                                        (this.state.filter === categorie.code ? 'selected-filter btn background-call-to-action ' : 'filter-text btn ')
                                    }
                                        onClick={() => this.state.handleFilter(categorie.code)}>
                                        {categorie.libelle.toUpperCase()}
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            ) : '';
    }
}


const CategorieFilter = connect(mapStateToProps, mainScreenAction)(CategorieFilterComponent);

export default CategorieFilter;