import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import { Button } from "../custom_button/custom_button";
import CreateQuestionModalComponent from "../createquestion/createquestion.modal"

export default class ModificationQuestion extends Component {

  constructor(props) {
    super(props)

    this.state = {
      showModalModification: false
    };
  }

  showModalModification = e => {
    this.setState({
      showModalModification: true
    });
  }

  hideModalModification = () => {
    this.setState({
      showModalModification: false
    });
  }

  render() {
    return (
      <div>
        {this.state.showModalModification ? <CreateQuestionModalComponent
          show={this.state.showModalModification}
          onHide={this.hideModalModification}
          question={this.props.question}
          idProjet={this.props.idProjet}
          showAlert={this.props.showAlert}
          setQuestionModify={this.props.setQuestionModify}>
        </CreateQuestionModalComponent> : ""}
        <Button className="margin-bottom-10" action="importante" onClick={this.showModalModification}> <Translation>{(t) => <p>{t('MODIFIER')}</p>}</Translation> </Button>
      </div>
    )
  }
}