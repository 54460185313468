const init_state = [];

const INIT_PROJETS = 'INIT_PROJETS';

export default function projets (state = init_state, action) {

    switch (action.type) {
        case INIT_PROJETS:
            return action.projets;
            default:
            return state;
    }
};



