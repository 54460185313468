import React from "react";
import { Form } from "react-bootstrap";
import { connect } from "react-redux";
import Button from "../custom_button/custom_button"
import { mainScreenAction } from "../../actions/MainScreenActions";
import { mapStateToProps } from "../../selectors/MainScreenSelector";
import projetsService from "../../services/projets.service";
import AnswersService from "../../services/reponse.service"
import utilsService from "../../services/utils.service";
import { Translation, withTranslation } from 'react-i18next';
import ModificationQuestion from "../modificationQuestion/modificationQuestion.component";

class InputTextAnswer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showAlertSuccess: false,
            answer: this.props.answered ? props.question.reponses[0].valeur : '',
            edition: false
        };
        this.handleChangeInputText = this.handleChangeInputText.bind(this);
        this.clearForm = this.clearForm.bind(this);
    }

    componentWillReceiveProps(props) {
        if (props.answered && props.question.reponses !== this.props.question.reponses) {
            this.setState({
                answer: props.answered ? props.question.reponses[0].valeur : ''
            })
        }
    }

    handleChangeInputText(e) {
        this.setState({ answer: e.target.value })
    }

    clearForm() {
        this.setState({
            answer: this.props.answered ? this.props.question.reponses[0].valeur : '',
            edition: false
        });
    }

    hideAlertSuccess = () => this.setState({ showAlertSuccess: false })

    submitAnswer = () => {
        if (this.state.edition || this.props.question.is_reponse_expired) {
            AnswersService.updateAnswer(this.props.question, this.state.answer).then(() => {
                this.setState({ showAlertSuccess: true, edition: false });
                projetsService.getProjetById(this.props.idProjet).then((resp) => {
                    const questions = utilsService.extractActiveQstsWithCategories(resp.data[0].noeuds);
                    this.props.initQuestions(questions);
                    this.props.showAlert({
                        type: 'success',
                        message: <Translation>{(t) => <p>{t('REPONSE_MODIFIEE')}</p>}</Translation>
                    });
                });
            })
        } else {
            AnswersService.postAnswer(this.props.question, this.state.answer).then(() => {
                this.setState({ showAlertSuccess: true, answer: '' });
                projetsService.getProjetById(this.props.idProjet).then((resp) => {
                    const questions = utilsService.extractActiveQstsWithCategories(resp.data[0].noeuds);
                    this.props.initQuestions(questions);
                    this.props.showAlert({
                        type: 'success',
                        message: <Translation>{(t) => <p>{t('REPONSE_ENREGISTREE')}</p>}</Translation>
                    });
                });
            })
        }
    }

    editAnswer = () => {
        this.setState({ edition: true });
    }

    deleteAnswer = () => {
        AnswersService.deleteAnswer(this.props.question.id, this.props.question.noeud_id).then(() => {
            this.setState({ showAlertSuccess: true, edition: false });
            projetsService.getProjetById(this.props.idProjet).then((resp) => {
                const questions = utilsService.extractActiveQstsWithCategories(resp.data[0].noeuds);
                this.props.initQuestions(questions);
                this.props.showAlert({
                    type: 'success',
                    message: <Translation>{(t) => <p>{t('REPONSE_SUPPRIMEE')}</p>}</Translation>
                });
            });
        })
    }

    getButtons() {
        let buttons;
        if (this.props.answered && !this.state.edition) {
            buttons = <div className="col-4 pr-0 text-right">
                <Button className="margin-bottom-10" action="importante" onClick={this.editAnswer} > <Translation>{(t) => <p>{t('MODIFIER')}</p>}</Translation> </Button>
                <Button className="margin-top-10" action="suppression" onClick={this.deleteAnswer} > <Translation>{(t) => <p>{t('SUPPRIMER')}</p>}</Translation> </Button>
            </div>;
        } else if (this.props.isDirection) {
            buttons = <div className="col-4 pr-0 text-right">
                <ModificationQuestion question={this.props.question} idProjet={this.props.idProjet} showAlert={this.props.showAlert} setQuestionModify={this.props.setQuestionModify} />
                <Button className="margin-top-10" action="suppression" onClick={() => { this.props.deleteAnswerDirection(this.props.question.id) }} > <Translation>{(t) => <p>{t('SUPPRIMER')}</p>}</Translation> </Button>
            </div>;
        } else {
            buttons = <div className="col-4 pr-0 text-right">
                <Button className="margin-bottom-10" action="importante" onClick={this.submitAnswer} disabled={!this.state.answer} > <Translation>{(t) => <p>{t('VALIDER')}</p>}</Translation> </Button>
                <Button className="margin-top-10" action="simple" onClick={this.clearForm} disabled={this.clearForm} > <Translation>{(t) => <p>{t('ANNULER')}</p>}</Translation> </Button>
            </div>;
        }

        return buttons;
    }

    render() {
        return (
            <>
                <Form ref={form => this.form = form}>
                    <div className="row mx-0 align-items-center">
                        <div className="col-8 pl-0">
                            {!this.props.isDirection ? (
                                <Form.Group controlId="answerInput" className="mb-0">
                                    <Form.Control as="textarea" placeholder={this.props.t('REPONSE')} className="custom-textarea bg-white" rows="4" value={this.state.answer} onChange={this.handleChangeInputText} disabled={(this.props.answered && !this.state.edition) || this.props.isDirection} />
                                </Form.Group>
                            ) : (
                                    <Form.Group controlId="answerInput">
                                        <div className="my-1">
                                            <p className="text-information"><Translation>{(t) => <b>{t('TYPE_DE_REPONSE')}</b>}</Translation><Translation>{(t) => <>{t('SAISIE_TEXTE')}</>}</Translation> </p>
                                        </div>
                                        <Form.Control as="textarea" className="custom-textarea bg-white" rows="4" value={this.state.answer} onChange={this.handleChangeInputText} disabled={(this.props.answered && !this.state.edition) || this.props.isDirection} />
                                    </Form.Group>)}
                        </div>

                        {this.getButtons()}

                    </div>
                </Form>
            </>
        );
    }
}

const InputTextAnswerContainer = connect(mapStateToProps, mainScreenAction)(InputTextAnswer);

export default withTranslation()(InputTextAnswerContainer);