const init_state = {
    currentUser: null
};

const INIT_CURRENT_USER = 'INIT_CURRENT_USER';

export default function currentUser (state = init_state, action) {

    switch (action.type) {
        case INIT_CURRENT_USER:
            return action.currentUser;
            default:
            return state;
    }
};



