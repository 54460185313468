import React, { Component } from 'react';
import { connect } from 'react-redux';
import UtilsService from '../../../services/utils.service';
import { mapStateToProps } from '../../../selectors/MainScreenSelector';
import { mainScreenAction } from '../../../actions/MainScreenActions';
import InputTextAnswer from '../../answers/inputtextanswer.component';
import ChoixSimpleAnswerContainer from '../../answers/simplechoiceanswer.component';
import RadioButtonAnswer from '../../answers/radiobuttonanswer.component';
import PillBoxContainerAnswer from '../../answers/pillboxanswer.component';
import InputNumericAnswerContainer from '../../answers/inputnumericanswer.component';
import DatePickerAnswerContainer from '../../answers/datepickeranswer.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleDown, faChevronCircleUp, faCircle, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import SnakeBarComponent from '../../snakebar/snakebar.component';
import { Translation, withTranslation } from 'react-i18next';
import './questions_directeur.css';
import CustomSwitch from '../../custom_inputs/custom_switch.component';
import QuestionService from '../../../services/questions.service';
import ModalReponseExpireeComponent from './modalQuestionDirecteur/modalReponseExpiree.component';
import ConfirmationSuppression from './modalQuestionDirecteur/confirmationSuppression.component';

class QuestionsDirecteurComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            categorie: this.props.categoriesMainScreen,
            selected: [],
            isAllDisplay: false,
            showAlertSuccess: false,
            questions: this.props.questionsMainScreen,
            initDataDone: false,
            sortConfig: [
                { column: 'libelle', type: 'label', isSortAsc: false },
                { column: 'categorie_libelle', type: 'label', isSortAsc: false },
                { column: 'date_debut_validite', type: 'date', isSortAsc: false },
                { column: 'duree_validite', type: 'number', isSortAsc: false },
                { column: 'nbr_signalements', type: 'number', isSortAsc: false },
                { column: 'nbr_reponses', type: 'number', isSortAsc: false },
                { column: 'nbr_perimees', type: 'number', isSortAsc: false },
                { column: 'statut', type: 'number', isSortAsc: false }
            ],
            searchTxt: ''
        }

        this.itemsRefs = {};
        this.showAlert = this.showAlert.bind(this);
        this.setQuestionModify = this.setQuestionModify.bind(this);
    }

    componentWillReceiveProps(props) {
        if (this.state.questions.length !== props.questionsMainScreen) {
            this.setState({
                questions: UtilsService.dynamicSort(this.state.sortConfig, this.props.questionsMainScreen, this.state.sortBy, false)
            })

        }
    }

    componentDidMount() {
        this.changeSortedBy('libelle');
    }

    deleteAnswerDirection = (id) => {
        this.setState({
            idSuppression: id,
            showSuppressionModal: true
        })
    }

    onClickHandler = (e, index) => {
        const hiddenElement = e.currentTarget.nextSibling;
        if (hiddenElement.className.indexOf("collapse show") > -1) {
            hiddenElement.classList.remove("show");
        } else {
            hiddenElement.classList.add("show");
            this.itemsRefs[index].scrollIntoView({ behavior: "smooth", block: "nearest", inline: "nearest" });
        }
        let selectedItems = [];
        if (!this.state.selected.includes(index)) {
            selectedItems = [...this.state.selected, index];
        } else {
            selectedItems = this.state.selected.filter(item => item !== index);
        }

        this.setState({
            selected: selectedItems
        })
    };

    changeSortedBy = (column) => {

        let newConfig = this.state.sortConfig;

        this.setState({
            questions: UtilsService.dynamicSort(newConfig, this.state.questions, column, true),
            sortConfig: newConfig,
            sortBy: column
        });
    }

    handleDiplayAll = () => {
        this.setState({
            isAllDisplay: true
        });
    }

    getDureeValideeHeader = () => {

        return (<div className="col-1 tri-selector reponsive-header-column" onClick={() => this.changeSortedBy('duree_validite')}>
            <h3 className="text-important">
                <Translation>{(t) => <p>{t('DUREE_VALIDITEE')}</p>}</Translation>
            </h3>
            <i className={this.state.sortConfig.find(currentConfig => currentConfig.column === 'duree_validite').isSortAsc
                ? "fa fa-angle-up arrow-margin custom-self-center" : "fa fa-angle-down arrow-margin custom-self-center"}></i>
        </div>);
    }

    getDureeValiditee = (question) => {
        let row;

        if (question.duree_validite) {
            row = <div>{question.duree_validite} <Translation>{(t) => <p>{t('MOIS')}</p>}</Translation> </div>;
        }
        else {
            row = <div>-</div>
        }
        return row;

    }

    refreshQuestion = (idQuestionSuppr) => {
        let newQuestion = this.state.questions;
        let indexQuestion = newQuestion.indexOf(newQuestion.find(question => question.id === idQuestionSuppr));
        newQuestion.splice(indexQuestion, 1)
        this.setState({
            question: newQuestion
        })
    }


    displayHeader() {
        return (
            <div className='row'>
                <ConfirmationSuppression
                    show={this.state.showSuppressionModal}
                    id={this.state.idSuppression}
                    hide={() => { this.setState({ showSuppressionModal: false }) }}
                    showAlert={this.showAlert}
                    refreshQuestion={this.refreshQuestion}
                >
                </ConfirmationSuppression>
                <div className="col-1 tri-selector reponsive-header-column" onClick={() => this.changeSortedBy('statut')}>
                    <h3 className="text-important">
                        <Translation>{(t) => <p>{t('ETAT')}</p>}</Translation>
                    </h3>
                    <i className={this.state.sortConfig.find(currentConfig => currentConfig.column === 'statut').isSortAsc
                        ? "fa fa-angle-up arrow-margin custom-self-center" : "fa fa-angle-down arrow-margin custom-self-center"}></i>

                </div>

                <div className="col-3 tri-selector reponsive-header-column" onClick={() => this.changeSortedBy('libelle')}>
                    <h3 className="text-important">
                        <Translation>{(t) => <p>{t('NOM')}</p>}</Translation>
                    </h3>
                    <i className={this.state.sortConfig.find(currentConfig => currentConfig.column === 'libelle').isSortAsc
                        ? "fa fa-angle-up arrow-margin custom-self-center" : "fa fa-angle-down arrow-margin custom-self-center"}></i>
                </div>
                <div className="col-1 tri-selector reponsive-header-column" onClick={() => this.changeSortedBy('date_debut_validite')}>
                    <h3 className="text-important">
                        <Translation>{(t) => <p>{t('DATE_CREATION')}</p>}</Translation>
                    </h3>
                    <i className={this.state.sortConfig.find(currentConfig => currentConfig.column === 'date_debut_validite').isSortAsc
                        ? "fa fa-angle-up arrow-margin custom-self-center" : "fa fa-angle-down arrow-margin custom-self-center"}></i>
                </div>

                {this.getDureeValideeHeader()}

                <div className="col-2 tri-selector reponsive-header-column" onClick={() => this.changeSortedBy('categorie_libelle')}>
                    <h3 className="text-important">
                        <Translation>{(t) => <p>{t('CATEGORIE')}</p>}</Translation>
                    </h3>
                    <i className={this.state.sortConfig.find(currentConfig => currentConfig.column === 'categorie_libelle').isSortAsc
                        ? "fa fa-angle-up arrow-margin custom-self-center" : "fa fa-angle-down arrow-margin custom-self-center"}></i>
                </div>

                <div className="col-1 tri-selector reponsive-header-column" onClick={() => this.changeSortedBy('nbr_reponses')}>
                    <h3 className="text-important custom-reponse">
                        <Translation>{(t) => <p>{t('REPONSES')}</p>}</Translation>
                    </h3>
                    <i className={this.state.sortConfig.find(currentConfig => currentConfig.column === 'nbr_reponses').isSortAsc
                        ? "fa fa-angle-up arrow-margin custom-self-center" : "fa fa-angle-down arrow-margin custom-self-center"}></i>
                </div>

                <div className="col-1 tri-selector reponsive-header-column" onClick={() => this.changeSortedBy('nbr_perimees')}>
                    <h3 className="text-important">
                        <Translation>{(t) => <p>{t('PERIMEES')}</p>}</Translation>
                    </h3>
                    <i className={this.state.sortConfig.find(currentConfig => currentConfig.column === 'nbr_perimees').isSortAsc
                        ? "fa fa-angle-up arrow-margin custom-self-center" : "fa fa-angle-down arrow-margin custom-self-center"}></i>
                </div>

                <div className="col-2 tri-selector reponsive-header-column" onClick={() => this.changeSortedBy('nbr_signalements')}>
                    <h3 className="text-important">
                        <Translation>{(t) => <p>{t('SIGNALEMENTS')}</p>}</Translation>
                    </h3>
                    <i className={this.state.sortConfig.find(currentConfig => currentConfig.column === 'nbr_signalements').isSortAsc
                        ? "fa fa-angle-up arrow-margin custom-self-center" : "fa fa-angle-down arrow-margin custom-self-center"}></i>
                </div>
            </div>
        )
            ;
    }

    showAlert(alert) {
        this.setState({
            typeAlert: alert.type,
            messageAlert: alert.message,
            showAlertSuccess: true
        })
    }

    setQuestionModify(questionModify) {
        const indexQuestionToModify = this.state.questions.findIndex(question => question.id === questionModify.id)
        let newQuestions = [...this.state.questions];
        if (questionModify.valeurs_question && questionModify.valeurs_question.length) {
            questionModify.valeurs_question.map(valeurQuestionNew => newQuestions[indexQuestionToModify].valeurs_questions.push(valeurQuestionNew))
            newQuestions[indexQuestionToModify].valeurs_questions.push(newQuestions[indexQuestionToModify].valeurs_questions.splice(newQuestions[indexQuestionToModify].valeurs_questions.map(function (valeurQuestion) { return valeurQuestion.libelle }).indexOf("Autres"), 1)[0]);
        }

        if (!questionModify.isSimpleModification) {
            newQuestions[indexQuestionToModify].reponses = [];
        }
        newQuestions[indexQuestionToModify] = {
            ...newQuestions[indexQuestionToModify],
            id_parent_question: questionModify.id_parent_question,
            complement: questionModify.complement,
            duree_validite: questionModify.duree_validite,
            categorie_libelle: this.state.categorie.find(categorie => questionModify.id_categorie === categorie.id).libelle,
            id_categorie: questionModify.id_categorie,
            libelle: questionModify.libelle,
            date_debut_validite: new Date(),
            date_maj: new Date(),
            signalements: [],
            nbr_signalements: 0
        }
        this.setState({
            questions: newQuestions
        });
    }

    handleChangeStatutQuestion = (event, question, index) => {
        let newQuestions = this.state.questions;
        let statut = event.target.checked
        newQuestions[index].statut = statut

        QuestionService.putQuestionStatut(question.id, statut).then((question) => {
            this.setState({
                questions: newQuestions,
                showAlertSuccess: true,
                messageAlert: statut ? 'La question a été activée' : 'La question a été désactivée'
            })
            this.props.initQuestions(this.state.questions);

        }
        )
    }

    hideAlertSuccess = () => this.setState({ showAlertSuccess: false });

    getColorCategorie(libelle_categorie) {
        var categorieColor = null;
        if (this.props.categories.length > 0) {
            this.props.categories.forEach(categorie => {
                if (libelle_categorie === categorie.libelle) {
                    categorieColor = categorie.color;
                }
            });
        }
        return categorieColor ? (<FontAwesomeIcon icon={faCircle} color={categorieColor} > </FontAwesomeIcon>) : '';
    }

    showModalSignalements(e, question) {
        this.setState({
            currentQuestion: question,
            showModalSignalements: true

        });
        e.stopPropagation();
    }

    hideModalSignalements = () => {
        this.setState({
            showModalSignalements: false

        });
    }


    showModalReponseExpiree(e, question) {
        this.setState({
            currentQuestion: question,
            showModalReponseExpiree: true

        });
        e.stopPropagation();
    }

    hideModalReponseExpiree = () => {
        this.setState({
            showModalReponseExpiree: false
        });
    }

    handleSearchTxt = (e) => {
        this.setState({
            searchTxt: e
        });
    }

    render() {
        return (
            <>
                {this.state.showModalReponseExpiree ? <ModalReponseExpireeComponent
                    show={this.state.showModalReponseExpiree}
                    onHide={this.hideModalReponseExpiree}
                    question={this.state.currentQuestion}
                    mode='PERIMEES'>
                </ModalReponseExpireeComponent> : ""}

                {this.state.showModalSignalements ? (<ModalReponseExpireeComponent
                    showAlert={this.showAlert}
                    setQuestionModify={this.setQuestionModify}
                    idProjet={this.props.idProjet}
                    show={this.state.showModalSignalements}
                    onHide={this.hideModalSignalements}
                    question={this.state.currentQuestion}
                    mode='SIGNALEES'>
                </ModalReponseExpireeComponent>) : ""}

                <div className="bg-white custom-background">
                    <SnakeBarComponent open={this.state.showAlertSuccess} duration={3000} severity={this.state.typeAlert} message={this.state.messageAlert} hide={this.hideAlertSuccess} />
                    <div className='row mx-4 justify-content-between'>
                        <div className='d-flex align-items-center custom-padding-bot' >
                            <h2 className='text-information'>

                                {this.props.statut == null && this.props.signalement == null ? (
                                    <Translation>{(t) => <p className="text-information">{t('QUESTION_ACTIVES_INACTIVES')}</p>}</Translation>
                                ) : ''}
                                {this.props.statut != null && this.props.statut ? (
                                    /*TODO Translation*/
                                    'Questions actives'
                                ) : ''}
                                {this.props.statut != null && !this.props.statut ? (
                                    /*TODO Translation*/
                                    'Questions inactives'
                                ) : ''}
                                {this.props.signalement != null && this.props.signalement ? (
                                    /*TODO Translation*/
                                    'Questions signalées'
                                ) : ''}
                            </h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-3">
                            <input className="form-control search-input " type="text"
                                onChange={(e) => this.handleSearchTxt(e.target.value)}
                                value={this.state.searchTxt}
                                placeholder={this.props.t('Recherche')} aria-label="Search" />
                        </div>
                    </div>

                    {this.state.questions.length > 0 ?
                        (
                            <div className="mx-4">
                                {this.displayHeader()}
                                {this.state.questions.filter((data) => {
                                    if (!this.state.searchTxt) return data;
                                    else if (data.libelle.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase()
                                        .includes(this.state.searchTxt.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase()))
                                        return data;
                                }).map((question, index) => {
                                    return (
                                        (index > 2 ? this.state.isAllDisplay : true) ?
                                            (<div key={question.id} ref={el => (this.itemsRefs[index] = el)} className="bg-contrast-white  hover-collapse  px-0 py-0 my-2">
                                                <div onClick={(e) => this.onClickHandler(e, index)} className='row card-header bg-contrast-white'>

                                                    <div className="col-1 d-flex align-items-center" >
                                                        <div>
                                                            <CustomSwitch
                                                                onClick={(e) => e.stopPropagation()}
                                                                icon={<FontAwesomeIcon icon={faEyeSlash} color="#BF2222"></FontAwesomeIcon>}
                                                                checkedIcon={<FontAwesomeIcon icon={faEye} color="#3ABF35" ></FontAwesomeIcon>}
                                                                checked={question.statut}
                                                                onChange={(event) => this.handleChangeStatutQuestion(event, question, index)}
                                                            >
                                                            </CustomSwitch>
                                                        </div>
                                                    </div>

                                                    <div className="col-3 d-flex align-items-center">
                                                        <div>
                                                            <h3 className="text-important">{question.libelle}</h3>
                                                            <h6>{question.complement}</h6>
                                                        </div>
                                                    </div>

                                                    <div className="col-1 d-flex align-items-center" >
                                                        <p className="text-important">{UtilsService.handleDateFormat(question.date_debut_validite)}</p>
                                                    </div>

                                                    <div className='col-1 d-flex align-items-center'>
                                                        {question.duree_validite ? <div>{question.duree_validite} <Translation>{(t) => <p>{t('MOIS')}</p>}</Translation> </div> : "-"}
                                                    </div>

                                                    <div className="col-2 d-flex align-items-center">
                                                        <h3 className="text-important">{this.getColorCategorie(question.categorie_libelle)} {question.categorie_libelle}</h3>
                                                    </div>

                                                    <div className='col-1 d-flex align-items-center'>
                                                        {question.nbr_reponses ? question.nbr_reponses : "-"}
                                                    </div>

                                                    <div className="col-1 d-flex align-items-center">
                                                        {question.nbr_perimees ? <button onClick={(e) => this.showModalReponseExpiree(e, question)} className="custom-button-animation"><span className="custom-dot"></span>{question.nbr_perimees}</button> : "-"}
                                                    </div>
                                                    <div className='col-1 d-flex align-items-center'>
                                                        {question.nbr_signalements ? <button key={question.id} onClick={(e) => this.showModalSignalements(e, question)} className="custom-button-animation"><span className="custom-dot"></span>{question.nbr_signalements}</button> : "-"}
                                                    </div>

                                                    <div className='toggle-icon-color col-1 custom-self-center' >
                                                        <FontAwesomeIcon color="#00aa9b" icon={this.state.selected.includes(index) ? faChevronCircleUp : faChevronCircleDown} className="hover-cursor-pointer float-right" />
                                                    </div>
                                                </div>
                                                <div className="collapse">
                                                    <hr className="custom-border-question"></hr>
                                                    <div className="row card-body bg-contrast-white mx-0">
                                                        <div className="col-12">
                                                            {question.type === 'SAISIE_TEXT' ? <InputTextAnswer question={question} showAlert={this.showAlert} setQuestionModify={this.setQuestionModify} idProjet={this.props.idProjet} answered={false} isDirection={true} deleteAnswerDirection={this.deleteAnswerDirection}></InputTextAnswer> : ''}
                                                            {question.type === 'LISTE_DEROULANTE' ? <ChoixSimpleAnswerContainer question={question} showAlert={this.showAlert} setQuestionModify={this.setQuestionModify} idProjet={this.props.idProjet} answered={false} isDirection={true} deleteAnswerDirection={this.deleteAnswerDirection}></ChoixSimpleAnswerContainer> : ''}
                                                            {question.type === 'RADIO_BOUTON' ? <RadioButtonAnswer question={question} showAlert={this.showAlert} setQuestionModify={this.setQuestionModify} idProjet={this.props.idProjet} answered={false} isDirection={true} deleteAnswerDirection={this.deleteAnswerDirection}></RadioButtonAnswer > : ''}
                                                            {question.type === 'LISTE_TAG' ? <PillBoxContainerAnswer key={question} question={question} showAlert={this.showAlert} setQuestionModify={this.setQuestionModify} idProjet={this.props.idProjet} answered={false} isDirection={true} deleteAnswerDirection={this.deleteAnswerDirection}></PillBoxContainerAnswer> : ''}
                                                            {question.type === 'SAISIE_NUMERIQUE' ? <InputNumericAnswerContainer question={question} showAlert={this.showAlert} setQuestionModify={this.setQuestionModify} idProjet={this.props.idProjet} answered={false} isDirection={true} deleteAnswerDirection={this.deleteAnswerDirection}></InputNumericAnswerContainer> : ''}
                                                            {question.type === 'DATE' ? <DatePickerAnswerContainer question={question} showAlert={this.showAlert} setQuestionModify={this.setQuestionModify} idProjet={this.props.idProjet} answered={false} isDirection={true} deleteAnswerDirection={this.deleteAnswerDirection}></DatePickerAnswerContainer> : ''}
                                                        </div>

                                                    </div>
                                                </div>

                                            </div>
                                            )
                                            : '')
                                })}
                            </div>
                        ) : (

                            <div className="text-center">
                                {this.props.statut == null && this.props.signalement == null ? (
                                    'Vous n\'avez pas de questions'
                                ) : ''}
                                {this.props.statut != null && this.props.statut ? (
                                    /*TODO Translation*/
                                    'Vous n\'avez pas de questions actives'
                                ) : ''}
                                {this.props.statut != null && !this.props.statut ? (
                                    /*TODO Translation*/
                                    'Vous n\'avez pas de questions inactives'
                                ) : ''}
                                {this.props.signalement != null && this.props.signalement ? (
                                    /*TODO Translation*/
                                    'Vous n\'avez pas de questions signalées'
                                ) : ''}
                            </div>
                        )
                    }

                    {(this.state.questions.length > 3 && !this.state.isAllDisplay && this.state.questions.length > 0) ?
                        <div className="col-12 white-background text-center">
                            <span className="btn-more-qst hover-cursor-pointer" onClick={this.handleDiplayAll}>
                                <Translation>{(t) => <p>{t('VOIR_PLUS_DE_QUESTIONS')}</p>}</Translation>
                            </span>
                        </div> : ''
                    }
                </div>
            </>
        );
    }
}

const QuestionsDirecteur = connect(mapStateToProps, mainScreenAction)(withTranslation()(QuestionsDirecteurComponent));

export default QuestionsDirecteur;
