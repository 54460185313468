import http from "../http-common.js";
import UtilsService from '../services/utils.service.js';

class ReponseService {

  updateAnswer(question, answer, autres, responseId) {
    if(UtilsService.isEmpty(question) || UtilsService.isEmpty(answer)){
      throw Error('la question ou la réponse ne peuvent être null');
    }
    return http.put(`/reponse/`+ question.reponses[0].id, {
      question_id: question ? question.id : '',
      answer: answer,
      autres: autres,
      responseId: responseId,
      id_noeud: question.noeud_id,
      duree_validitee: question.duree_validite,
      id_user: sessionStorage.getItem("user")
    });
  }

  postAnswer(question, answer, autres, responseId) {
    if(UtilsService.isEmpty(question) || UtilsService.isEmpty(answer)){
      throw Error('la question ou la réponse ne peuvent être null');
    }
    return http.post(`/reponse/`, {
      question_id: question ? question.id : '',
      answer: answer,
      autres: autres,
      responseId: responseId,
      id_noeud: question.noeud_id,
      duree_validitee: question.duree_validite,
      id_user: sessionStorage.getItem("user")
    });
  }
  
  deleteAnswer(id_question, id_noeud){
    return http.delete('/reponse/' + id_question + '/' + id_noeud);
  }

  getInfoReponseExpirees(id_question) {
    return http.get('/reponse/expiree/' + id_question);
  }

}

export default new ReponseService();