const init_state_filter = {
    qstNonRep_filter: 'ALL',
    qstRep_filter: 'ALL'
}

const UPDATE_FILTER_REP = 'UPDATE_FILTER_REP';
const UPDATE_FILTER_NON_REP = 'UPDATE_FILTER_NON_REP';

export default function filter (state = init_state_filter, action) {

    switch (action.type) {
         case UPDATE_FILTER_REP: return {
            qstNonRep_filter: state.qstNonRep_filter,
            qstRep_filter: action.filter
        }

        case UPDATE_FILTER_NON_REP: return {
            qstNonRep_filter: action.filter,
            qstRep_filter: state.qstRep_filter
        }

        default:
            return state;
    }
};